.seo-cities {
    padding: 20px;
    background-color: #fff;
    font-family:'Inter';
    font-size:2rem;
    text-align:center;
    max-width:1500px;
    margin-left:auto;
    margin-right:auto;
    padding-left:0px;
    padding-right:0px;
    border:solid;border-top:1px solid #ddd;border-left: none;border-bottom:none;border-right:none;
    
  }

  @media (max-width:1024px) {
    .seo-cities {
      padding: 15px;
      padding-left:0px;
      padding-right:0px;
      background-color: #fff;
      font-family:'Inter';
      font-size:2rem;
      text-align:center;
      max-width:1500px;
      margin-left:auto;
      margin-right:auto;
      
      
    }
  }

  
  @media (max-width:768px) {
    .seo-cities {
      padding: 0px;
      padding-top:15px;
      padding-bottom:15px;
      background-color: #fff;
      font-family:'Inter';
      font-size:2rem;
      text-align:center;
      max-width:1500px;
      margin-left:auto;
      margin-right:auto;
      
      
    }
  }
  
  .seo-cities h2 {
    text-align: left!important;
    margin-top:15px!important;
    margin-bottom:15px!important;
    font-size: 1rem!important;
    color: #000;
    font-family:'Inter'!important;}
  
  .seo-cities-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5px;
  }

  .seo-cities-grid a {color:black!important;}

  .seo-cities-grid a {font-size:11px;text-align:left;}
  
  .city-link {
    color: #000!important;
    text-decoration: none;
    font-size: 1.5rem;
    line-height: 1.6;
    text-align: left;
    display: block;
  }
  
  .city-link:hover {
    text-decoration: underline;
  }
  
  .read-more-btn {
    background: #9CDCF8!important;
    color: black!important;
    border: none!important;
    font-size: 1rem!important;
    padding: 15px 15px!important;
    cursor: pointer!important;
    display: flex!important;
    align-items: left!important;
    font-family:'Inter'!important;
    font-weight:500!important;
    margin-top:15px!important;
    border-radius:5px!important;
  }
  
  .read-more-btn:hover {
    text-decoration: underline;
  }
  
  .down-arrow {
    margin-left: 5px;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .seo-cities-grid {
      grid-template-columns: repeat(2, 1fr);
    }



    .content-image-section.text-right .text-content h2 {text-align:center;}



  }
  
  @media (max-width: 400px) {
    .seo-cities-grid {
      grid-template-columns: 1fr;
    }
  }
  