.advertising-partners-container {
  overflow: hidden;
  width: 100%;
  padding: 15px;
  background-color:white;
  justify-content: center;
  align-items: center;
  align-content: center;
  display:flex;
}

.advertising-partners-ticker-wrapper {
  overflow: hidden;
  display: flex;
  align-items: center;
  max-width:1500px;
}

.advertising-partners-ticker {
  display: flex;
  animation: advertisingPartnersScroll 30s linear infinite;
  
}

.advertising-partners-image {
  width: 168px;
  height: 63px;
  object-fit: cover;
  margin-right: 50px;
}

@keyframes advertisingPartnersScroll {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(-50%);
  }
}

/* Mobile-specific styles */
@media (max-width: 768px) {


  .advertising-partners-image {
      width: 140px; /* Image takes up the full width of the container */
      height: 56px; /* Maintain aspect ratio */
      margin-right: 0; /* No margin between images */
  }
}
