/* Container Styling */
.widget-container {
  background-color: #F3F3F3;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
}

.custom-listing-container {
  max-width: 1500px;
  margin: 0 auto;
  width: 100%;
}

.custom-listing-container h2 {
  font-family: 'Inter';
  font-size: 3.5rem;
  font-weight: 200;
  min-height: 110px;
  margin-top: 1vw;
  padding-top: 2vw;
  color:#1b478c;
}

.slider-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
}

@media (max-width:1024px) {
  div.listing-info > div.listing-details > div:nth-child(3) {display:none;}
}



.slider {
  display: flex;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  white-space: nowrap; /* Prevent flex items from wrapping to create a continuous effect */
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  margin: 10px 0; /* Adjust margins */
}

.custom-listing-column {
  flex: 0 0 calc(100% / 5 - 20px); /* Adjust to fit six listings */
  width: calc(100% / 5 - 20px);
  padding: 0;
  text-align: center;
  margin: 0 10px;
  border-radius: 5px;
  transition: transform 0.3s;
  background: white;
  position: relative;
  overflow: hidden;
  height: 400px;
}

.custom-listing-column:hover {
  
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
}


.image-container {
  width: 100%;
  height: 100%; /* Full height of the card */
  overflow: hidden;
  position: relative; /* Allow absolute positioning of other elements */
  
}

.image-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  z-index: 1; /* Ensure overlay sits above the image */
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  
}

.overlay-icons {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px; /* Space between the "buttons" */
  z-index:20;
  border-radius: 8px;
  
}

.overlay-button {
  border:None;
  border-width:1px;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  font-size: 0.8rem;
  font-weight: 400;
  display: inline-block;
  background-color:rgba(0, 0, 0, 0.7);
}

.beds-baths-container {
  display: flex;
  gap: 5px; /* Space between beds and baths buttons */
}

.listing-widget-price {
  font-size: 1rem;
  font-weight: bold;
}

.list-button {
  position: absolute;
  bottom: 10px;
  left: 50%; /* Start at 50% of the width */
  transform: translateX(-50%); /* Move back by 50% of the button width to center it */
  background-color:rgba(0, 0, 0, 0.7);
  padding: 10px 20px;
  border-radius: 3px;
  font-size: 1rem;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  color: black;
  border: 1px solid black;
  background:rgba(0, 0, 0, 0.7);
  z-index: 20;
}


@media (max-width: 768px) {
  .custom-listing-column {
    width: calc(100% - 40px); /* Full width minus margin for gap between listings */
    margin: 0 20px 20px 20px; /* Gap between listings and centered listing */
  }

  .listing-info {
    padding-left:10px;
    padding:0px;
    padding-right:0px!important;
    text-align: left;
    background: white;
    padding-bottom: 20px;
    padding-top: 20px;
}

  .slider-button.left, .slider-button.right {
    width: 56px;
    height: 56px; /* Ensure the button is a perfect circle */
    font-size: 1.5rem; /* Adjust button size for mobile */
  }

  .slider-button.left {
    left: 5px; /* Fine-tuned position for mobile */
  }

  .slider-button.right {
    right: 5px; /* Fine-tuned position for mobile */
  }
}



.custom-listing-column a {
  color: black;
  text-decoration: none;
  display: block;
}

.slider::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #9CDCF8;
  border: 1px solid black;
  color: black;
  width: 56px;
  height: 56px;
  cursor: pointer;
  z-index: 10;
  border-radius: 50%; /* Keep button round */
  transition: background-color 0.3s, transform 0.3s;
  display: flex;
  justify-content: center;
  align-items: center; /* Center the Font Awesome icon inside the button */
}

.custom-listing-column .list-button {
  font-size: 1rem;
  max-width: 100%;
  font-weight:bold;
  padding: 10px;

  background-color: #9CDCF8;
  color: black;
  font-weight:700;
  font-family: 'Inter';
  border: 0px solid black;
  border-radius: 5px;
  cursor: pointer;
  text-transform: capitalize;
  display: inline-block;
  font-weight: 200;
  text-decoration: none;
  width: 95%;
}

@media (max-width: 1024px) {
  .custom-listing-column {
    flex: 0 0 calc(100% / 3 - 20px); /* Adjust to fit three listings */
    width: calc(100% / 3 - 20px); /* Adjust to fit three listings */
    margin: 0 10px; /* Space between listings */
  }

  .slider-button.left, .slider-button.right {
    width: 56px;
    height: 56px; /* Ensure the button is a perfect circle */
    font-size: 1.5rem; /* Adjust button size for mobile */
  }

  .slider-button.left {
    left: 5px; /* Fine-tuned position for mobile */
  }

  .slider-button.right {
    right: 5px; /* Fine-tuned position for mobile */
  }

  .overlay-button {
    border: None;
    border-width: 1px;
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
    font-size: 0.7rem;
    font-weight: 400;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.7);

}

.custom-listing-column .list-button {
  font-size: 0.7rem;
  max-width: 100%;
  font-weight: bold;
  padding: 10px;
  background-color: #9CDCF8;
  color: black;
  font-weight: 700;
  font-family: 'Inter';
  border: 0px solid black;
  border-radius: 5px;
  cursor: pointer;
  text-transform: capitalize;
  display: inline-block;
  font-weight: 200;
  text-decoration: none;
  width: 95%;
}

}

@media (max-width: 768px) {
  .custom-listing-column {
    flex: 0 0 calc(50% - 20px); /* Adjust to fit two listings per row */
    width: calc(50% - 20px); /* Adjust to fit two listings per row */
    margin: 0 10px; /* Space between listings */
    height:250px;
  }

  .slider-button.left, .slider-button.right {
    width: 56px;
    height: 56px; /* Ensure the button is a perfect circle */
    font-size: 1.5rem; /* Adjust button size for mobile */
  }

  .slider-button.left {
    left: 5px; /* Fine-tuned position for mobile */
  }

  .slider-button.right {
    right: 5px; /* Fine-tuned position for mobile */
  }

  .overlay-button {
    border: None;
    border-width: 1px;
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
    font-size: 0.6rem;
    font-weight: 400;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.7);
}

.custom-listing-column .list-button {
  font-size: 0.5rem;
  max-width: 100%;
  font-weight: bold;
  padding: 10px;
  background-color: #9CDCF8;
  color: black;
  font-weight: 700;
  font-family: 'Inter';
  border: 0px solid black;
  border-radius: 5px;
  cursor: pointer;
  text-transform: capitalize;
  display: inline-block;
  font-weight: 200;
  text-decoration: none;
  width: 95%;
}
}

.widget-container {
  background-color: #F3F3F3;
  padding: 30px 60px;
  display: flex;
  justify-content: center;
  position: relative;
  padding-top:0px;
  width:100%;
}

.custom-listing-container {
  max-width: 1500px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.slider-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 0 0;
}

.slider {
  display: flex;
  overflow-x: hidden;
  white-space: nowrap;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  gap: 0px;
}

.custom-listing-column {
  flex: 0 0 calc(100% / 5 - 20px);
  text-decoration: none;
  color: inherit;
  background: white;
  border-radius: 5px!important;
  overflow: hidden;
  transition: all 0.3s ease;
  min-height: 200px;
  position: relative;
  border:solid;
  border-color:rgba(0, 0, 0, 0.171);
  border-width:0px;
  margin-left:10px!important;
  margin-right:10px!important;
}

.custom-listing-column:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 25px rgba(156, 220, 248, 0.3);
}

.custom-listing-column:hover .image-container img {
  transform: scale(1.05);
}

.image-container {
  width: 100%;
  height: 230px;
  overflow: hidden;
  position: relative;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.cashback-badge {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
  background-color: #9CDCF8;
  padding: 8px 15px;
  border-radius: 5px;
}

.cashback-badge a {
  color: black;
  text-decoration: none;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}

.listing-info {
  padding: 15px;
  text-align: left;
  background: white;
  padding-bottom:20px;
  padding-top:20px;
}

.listing-title {
  margin-bottom: 10px;
  margin-top:10px;
}

.street-address {
  font-size: 22px;
  font-weight: 200;
  color: black;
  margin: 0;
  font-family: 'Inter';
  line-height: 1.2;
}

.community {
  color: #5f5e5e;
  font-size: 14px;
  margin: 5px 0;
  font-family: 'Inter';
}

.separator-dot {
  margin: 0 5px;
}

.listing-details {
  display: flex;
  gap: 8px;
  margin: 12px 0;
}

.custom-listing-container .listing-detail-item {
  background-color: #F3F3F3;
  padding: 5px 10px!important;
  font-size: 14px!important;
  font-family: 'Inter'!important;
  border-radius: 5px!important;
  transition: background-color 0.3s ease!important;
  border:solid;
  border-color:rgba(0, 0, 0, 0.473);
  border-width:0px;
}

.custom-listing-column:hover .listing-detail-item {
  background-color: #f0f0f0;
}

.listing-price {
  font-size: 22px!important;
  font-weight: 600!important;
  color: #3B6AA7!important;
  font-family: 'Inter'!important;
  text-align: left!important;
  margin-top: 0px!important;

}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #9CDCF8;
  font-size: 2.5rem;
  cursor: pointer;
  z-index: 10;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.slider-button:hover {
  transform: translateY(-50%) scale(1.1);
  color: white;
}

.slider-button.left {
  left: -60px!important;
}

.slider-button.right {
  right: -60px!important;
}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent!important;
  border: 0px solid black;
  color: black!important;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 10;
  border-radius: 0px;
  transition: background-color 0.3s, transform 0.3s;
}

@media (max-width: 1024px) {
  .custom-listing-column {
    flex: 0 0 calc(100% / 3 - 16px);
    min-height: 350px;
  }
  
  .widget-container {
    padding: 30px 40px;
  }
  
  .slider-button.left {
    left: -10px;
  }
  
  .slider-button.right {
    right: -10px;
  }
}

@media (max-width: 768px) {
  .custom-listing-column {
    flex: 0 0 calc(50% - 16px);
  }
  
  .widget-container {
    padding: 20px;
  }
  
  .image-container {
    height: 200px;
  }
  
  .street-address {
    font-size: 16px;
  }
  
  .listing-price {
    font-size: 18px;
  }

  .cashback-badge {
    padding: 6px 12px;
  }

  .cashback-badge a {
    font-size: 12px;
  }
}

@media (max-width: 440px) { .cashback-badge {
  padding: 6px 12px;
  top:0px;
  left:0px;
  border-radius: 5px 5px 0px 0px;
  width:100%;
}}

@media (max-width: 768px) {

  .widget-container {padding:0px;}

  .icon {width: 20px !important;
    height: 20px !important;}

  .slider-button.left {
    left: 0px !important}

    .slider-button.right {
      right: 0px !important}

      .custom-listing-container .listing-detail-item {
        background-color: #F3F3F3;
        padding: 5px 10px !important;
        font-size: 13px !important;
        font-family: 'Inter' !important;
        border-radius: 5px !important;
        transition: background-color 0.3s ease !important;
        border: solid;
        border-color: rgba(0, 0, 0, 0.473);
        border-width: 0px;}
  
}

/* Loading animation */
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.loading {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 1000px 100%;
}

.custom-listing-container .street-address {
  font-size:16px!important;
  color:black;
  font-weight:600!important;
  text-align:left;
  text-transform: capitalize;
  margin-bottom:5px!important;

}

.community, .street-address {font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: #555;
  text-align: left;
  
}

.custom-listing-container .listing-details {
  display: flex;
  justify-content: left;
  margin: 0px 0px 10px 0px;
  gap: 6px;
  font-family: 'Inter', serif;
  font-size: 16px;
  justify-content:start;
}

/* Smaller icon size for bed and bath icons */
.listing-detail-item .fa-bed, 
.listing-detail-item .fa-bath {
  font-size: 10px; /* Adjust the size as needed */
  margin-right: 5px; /* Optional: Add spacing between icon and text */
}

.icon {
  width: 17px;
  height: 17px;
}

@media (max-width:1024px) {
  .cashback-badge a {
    color: black;
    text-decoration: none;
    font-family: 'Inter';
    font-size: 10px;
    font-weight: 500;
    white-space: nowrap;
}

.custom-listing-container .street-address {
  font-size: 17px;
  color: black;
  font-weight: 600;
  text-align: left;
  text-transform: capitalize;
  margin-bottom: 5px;
}

.custom-listing-container .community, .street-address {
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  color: #555;
  margin: 0;
}

.listing-price {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #3B6AA7 !important;
  font-family: 'Inter' !important;
  text-align: left !important;
  margin-top: 0px !important;
}
}

@media (max-width:1550px) {

  .custom-listing-column {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% / 4 - 20px);
    text-decoration: none;
    color: inherit;
    background: white;
    border-radius: 5px !important;
    overflow: hidden;
    transition: all 0.3s ease;
    min-height: 200px;
    position: relative;
    border: solid;
    border-color: rgba(0, 0, 0, 0.171);
    border-width: 0px;
    margin-left: 10px !important;
    margin-right: 10px !important;
}

}

@media (max-width:1210px) {

  .custom-listing-column {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% / 3 - 20px);
    text-decoration: none;
    color: inherit;
    background: white;
    border-radius: 5px !important;
    overflow: hidden;
    transition: all 0.3s ease;
    min-height: 200px;
    position: relative;
    border: solid;
    border-color: rgba(0, 0, 0, 0.171);
    border-width: 0px;
    margin-left: 10px !important;
    margin-right: 10px !important;
}

}

@media (max-width:1050px) {

  .custom-listing-column {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% / 3 - 20px);
    text-decoration: none;
    color: inherit;
    background: white;
    border-radius: 5px !important;
    overflow: hidden;
    transition: all 0.3s ease;
    min-height: 200px;
    position: relative;
    border: solid;
    border-color: rgba(0, 0, 0, 0.171);
    border-width: 0px;
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.slider-button.right {
  right: -40px !important;
}

.slider-button.left {
  left: -40px !important;
}}

@media (max-width:768px) {

  .custom-listing-column {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% / 2 - 20px);
    text-decoration: none;
    color: inherit;
    background: white;
    border-radius: 5px !important;
    overflow: hidden;
    transition: all 0.3s ease;
    min-height: 370px;
    position: relative;
    border: solid;
    border-color: rgba(0, 0, 0, 0.171);
    border-width: 0px;
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.slider-button.right {
  right: 0px !important;
}

.slider-button.left {
  left: 0px !important;
}

    .custom-listing-container .street-address {
        font-size: 13px!important;
        color: black;
        font-weight: 600;
        text-align: left;
        text-transform: capitalize;
        margin-bottom: 5px;
        text-wrap:auto;
    }

    .custom-listing-container .community {
      font-family: 'Inter', sans-serif;
      font-size: 10px!important;
      color: #555;
      margin: 0;
  }
}

