.article-detail-container {
    max-width: 1200px; /* Adjust as needed */
    margin: 0 auto; /* Center the container */
    padding: 20px; /* Add some padding */
    text-align: left; /* Ensure text alignment is left */
  }
  
  .article-detail-container h2,
  .article-detail-container .article-category,
  .article-detail-container .article-date {
    text-align: left;
    font-size:32px;
  }
  

  .articles-container h2 {

    color:#000;
    font-size:2.5rem;
    font-family:'Inter';
  
  }

  @media (max-width:768px) {
    .articles-container h2 {
      color: #000;
      font-size: 2rem;
      font-family: 'Inter';
  }
  }

  .article-content p {
    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
    font-family:'Inter';
    text-align: left;
  }

  .article-content ul {

    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
    font-family:'Inter';
    text-align: left;



  }
  
  .article-content {
    text-align: left; /* Ensure text alignment is left */
  }
  
  .error-message {
    color: red; /* Optional: Styling for error messages */
    font-size: 18px;
    margin-top: 20px;
  }
  
  .articles-container {
    display: flex;
  }
  
  .sidebar {
    position: sticky;
    top: 0;
    height: 100vh;
    background-color: #fff;
    box-sizing: border-box;
    border-right: 1px solid #d6d6d6;
  }
  
  .sidebar h3 {
    font-weight: bold;
  }
  
  .search-bar {
    width: 90%;
    margin: 1rem;
    padding: 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 0px;
    font-size: 20px;
  }
  
  .categories, .recent-articles {
    margin-bottom: 2rem;
  }
  
  .category-item, .sidebar-item {
    cursor: pointer;
    padding: 1rem;
    border-bottom: 1px solid #ddd;
  }
  
  .category-item:hover, .sidebar-item:hover {
    background-color: #9cdcf83d;
  }
  
  .articles-container .content .article-date {
    font-size: 1rem;
    color: #666;
  }
  
  .article-category {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .content {
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
    font-family:'Inter';
  }
  

  
  .content p {
    line-height: 1.75rem;
    font-size: 1rem;
  }
  
  @media (max-width: 768px) {
    .articles-container {
      flex-direction: column;
    }
  
    .sidebar {
      width: 100%;
      height: auto;
      position: relative;
    }
  
    .content {
      width: 100%;
    }
  }
  
  .sidebar-divider {
    border: none;
    border-bottom: 1px solid #d6d6d6;
    margin: 0 1rem 1rem 1rem;
  }
  
  .articles-container, .sidebar, .content, .category-item, .sidebar-item, .content h2, .content p {
    text-align: left;
  }
  
  .content {
    background-color: #f9f9f9;
  }
  
  .article-preview {
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
  
  .article-preview p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  
  .read-more-button {
    display: inline-block;
    margin-top: 1rem;
    padding: 0.5rem 2rem;
    background-color: #9cdcf8;
    color: black;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    font-size: 19px;
    font-weight: 700;
    font-family: 'Inter';
  }
  
  .read-more-button:hover {
    background-color: #8fcee9;
  }
  
  .articles-container hr {
    height: 1px;
  }
  
  .categories h3,
  .recent-articles h3,
  .search-section h3 {
    padding: 0rem 1rem;
  }
  
  .view-all-button {
    display: block;
    width: 90%;
    margin: 1rem auto;
    padding: 0.5rem;
    background-color: #9cdcf8;
    color: black;
    border: none;
    text-align: center;
    font-family: Inter, sans-serif;
    cursor: pointer;
    font-size: 19px;
    font-weight: 700;
    border-radius:5px;
  }
  
  .view-all-button:hover {
    background-color: #7abce0;
  }
  
  .sidebar-item.highlighted {
    background-color: #9cdcf83d;
  }
  
  .articles-container p {
    font-weight: 300;
    font-size: 1.5rem;
    line-height: 36px;
    font-family:'Inter';
  }
  
  @media (max-width:768px) {
    .articles-container p {
      font-weight: 300;
      font-size: 1rem;
      line-height: 36px;
      font-family:'Inter';
    }
  }
  .sidebar-item, .category-item {
    display: block;
    padding: 10px 15px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
  }
  
  .sidebar-item:hover, .category-item:hover {
    background-color: #f0f0f0;
  }
  
  .selected {
    background-color: #e0e0e0;
  }
  
  .sidebar-item div, .category-item div {
    margin: 0;
    padding: 0;
  }
  