/* General Styling for Landing Page */
.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center content vertically */
  padding: 20px;
  background-color: #f3f3f3;
  color: black;
  min-height: 100vh; /* Full viewport height */
  box-sizing: border-box;
}

.landing-page-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  flex-direction: column;
}

.landing-page-title {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  color: #333;
}

.landing-page-subtitle {
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
  color: #555;
  margin-top: 10px;
}

.landing-page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.landing-page-button {
  font-family: 'Inter', sans-serif;
  font-size: 1.5rem;
  color: #000;
  background-color: #9cdcf8;
  padding: 15px 30px;
  border-radius: 8px;
  text-align: center;
  transition: background-color 0.3s;
  width: 500px;
  max-width: 100%;
  cursor: pointer;
}

.landing-page-button:hover {
  background-color: #86c2db;
}

/* Style for the Back button */
.back-button-custom {
  background-color: #e0e0e0;
  color: #555555;
  padding: 15px 30px;
  border-radius: 8px;
  font-family: 'Inter', sans-serif;
  font-size: 1.5rem;
  text-align: center;
  width: 500px;
  max-width: 100%;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border: none;
}

.back-button-custom:hover {
  background-color: #d0d0d0;
  color: #333333;
}

.landing-page-text {
  font-size: 1.2rem;
  color: #333;
  margin-top: 20px;
  text-align: center;
  max-width: 600px;
  line-height: 1.6;
}

/* Icon Boxes */
.landing-page .icon-boxes-row {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 40px;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.landing-page .icon-box {
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  color: #3b6aa7;
  padding: 10px;
  box-shadow: none;
}

.landing-page .icon-box-image {
  width: 50px;
  height: auto;
  margin-bottom: 10px;
}

.landing-page .icon-box-heading {
  font-size: 1rem;
  color: #333;
  margin: 0;
  text-align: center;
  font-weight: 400;
}

/* Responsive Design */
@media (max-width: 768px) {
  .landing-page-title {
    font-size: 1.5rem;
  }

  .landing-page-button {
    font-size: 1.2rem;
    padding: 12px 24px;
    width: 300px;
  }

  .back-button-custom {
    font-size: 1.2rem;
    padding: 12px 24px;
    width: 300px;
  }

  .landing-page .icon-box {
    width: 100px;
  }

  .landing-page .icon-box-image {
    width: 40px;
  }

  .icon-box-heading {
    font-size: 0.9rem;
  }
}
