/* Global Styles */
html,
body {
  height: 100%;
  margin: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

body {
  box-sizing: border-box;
  overflow-y: auto; /* Allow vertical scrolling on the body */
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.selling-process h2 {
  font-size:4.5rem;
  font-weight:200;
  font-family: 'Inter';
}

.free-home-evaluation {
  justify-content: center;
  text-align: center;
  max-width: 1500px !important;
  background-color:  white;
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
  padding: 80px 15px; /* Increased padding for a more spacious feel */
  margin-top: 15px;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease; /* Smooth scaling on hover */
  margin-bottom:15px;
}

@media (max-width:1550px) {
  .free-home-evaluation {
    justify-content: center;
    text-align: center;
    max-width: 1500px !important;
    background-color: white;
    border-radius: 8px;
    margin-left: 15px;
    margin-right: 15px;
    padding: 40px 20px; /* Increased padding for a more spacious feel */
    margin-top: 15px;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    
    transition: transform 0.3s ease; /* Smooth scaling on hover */
    margin-bottom:20px;
  }
}

.free-home-evaluation:hover {
  transform: scale(1.02); /* Slight zoom effect on hover */
}



.free-home-evaluation:hover::before {
  right: -20px; /* Stripe moves slightly on hover */
}

.evaluation-text {
  text-align: left;
  margin: 15px;
  margin-left:0px;
  margin-right:15px;
  font-weight: 700;
  font-size: 60px;
  padding: 15px;
  padding-top:0px;
  margin-top:0px;
  position: relative;
  z-index: 2;
  color: #333; /* Darker text color for better contrast */
  margin-bottom:0px;
}

.evaluation-para {text-align:left;padding:15px;font-size:1.5rem;margin-top:0px;padding-top:0px;padding-right:90px;margin-bottom:0px;}

.free-home-evaluation .read-more-link {margin-left:15px;margin-top:15px!important;}

.partner-text {
  color: #1B478C;
  font-weight: 800;
}


@media (max-width:768px) {
  .free-home-evaluation {
    justify-content: center;
    text-align: center;
    max-width: 1500px !important;
    background-color: white;
    border-radius: 8px;
    margin-left: 15px;
    margin-right: 15px;
    padding: 40px 20px; /* Increased padding for a more spacious feel */
    margin-top: 20px;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    
    transition: transform 0.3s ease; /* Smooth scaling on hover */
  }
  
  .free-home-evaluation:hover {
    transform: scale(1.02); /* Slight zoom effect on hover */
  }
  
 
  
  .free-home-evaluation:hover::before {
    right: -20px; /* Stripe moves slightly on hover */
  }
  
  .evaluation-text {
    text-align: left;
    margin: 15px;
    margin-left:0px;
    margin-right:15px;
    font-weight: 700;
    font-size: 50px;
    padding: 15px;
    position: relative;
    z-index: 2;
    color: #333; /* Darker text color for better contrast */
  }
  
  .evaluation-para {text-align:left;padding:15px;font-size:1rem;margin-top:0px;padding-top:0px;padding-right:90px;margin-bottom:0px;}
  
  .free-home-evaluation .read-more-link {margin-left:15px;margin-top:15px!important;}
  
  .partner-text {
    color: #1B478C;
    font-weight: 800;
  }
}

@media (max-width:620px) {
  .free-home-evaluation {
    justify-content: center;
    text-align: center;
    max-width: 1500px !important;
    background-color: white;
    border-radius: 8px;
    margin-left: 15px;
    margin-right: 15px;
    padding: 40px 20px; /* Increased padding for a more spacious feel */
    margin-top: 20px;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    
    transition: transform 0.3s ease; /* Smooth scaling on hover */
  }
  
  .free-home-evaluation:hover {
    transform: scale(1.02); /* Slight zoom effect on hover */
  }
  
  .free-home-evaluation::before {
    content: "";
    position: absolute;
    top: -50%;
    right: 0;
    width: 120px; /* Slightly wider stripe */
    height: 200%;
    background-color: transparent;
    transform: rotate(-45deg);
    z-index: 1;
    transition: all 0.5s ease; /* Smooth transition for attention-grabbing effect */
  }
  
  .free-home-evaluation:hover::before {
    right: -20px; /* Stripe moves slightly on hover */
  }
  
  .evaluation-text {
    text-align: center;
    margin: 15px;
    margin-left:0px;
    margin-right:15px;
    font-weight: 700;
    font-size: 40px;
    padding: 15px;
    position: relative;
    z-index: 2;
    color: #333; /* Darker text color for better contrast */
  }
  
  .evaluation-para {text-align:center;padding:15px;font-size:1rem;margin-top:0px;padding-top:0px;padding-right:15px;margin-bottom:0px;}
  
  .free-home-evaluation .read-more-link {margin-left:auto;margin-top:15px!important;margin-right:auto;}
  
  .partner-text {
    color: #1B478C;
    font-weight: 800;
  }

  /* Additional subtle styling to elevate the look */
.evaluation-text::after {
  content: '';
  display: block;
  width: 80px;
  height: 5px;
  background-color: #9CDCF8;
  margin:auto;
  margin-top: 15px;
}
}








@media (max-width:768px) {
  .home-page-map {
    max-width:350px;
  }
}

/* Container for Selling Process */
.selling-process-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure it takes the full viewport height */
}

.commission-explained-button {
  margin-top: 3.5rem;
  font-size: 1.5rem;
  max-width: 450px;
  padding: 22px 35px;
  background-color: #9cdcf8;
  color: black;
  font-family: 'Inter';
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 200;
}

@media (max-width:1024px) {
  .two-column-section {display:block!important;}

 #save-more .left-column {padding:30px!important;border-radius:5px;}
}

.faq-sell-section {
  display: flex;
  background-color: #e5eef1;
  padding: 20px;
}

.faq-column {
  flex: 1;
  background-color: white;
  padding: 20px;
  margin-right: 10px;
  border-radius: 5px;
  margin-top: 50px;
}

.sell-column {
  flex: 1;
  background-color: #252525;
  padding: 20px;
  color: white;
  margin-left: 10px;
  border-radius: 5px;
}

.faq-column h2,
.sell-column h4 {
  color: #252525;
}

.sell-column h6 {
  font-size: 1.5rem;
  font-family: 'Inter';
  text-align: left;
  margin-bottom: 50px;
  margin-top: 0px;
  font-weight: 300;
}

.sell-column h4 .partners-text {
  color: #9cdcf8; /* Adjust the color as needed */
  text-shadow: 1px 1px 0px #577999;
}

.contact-section .two-column-section h3 {
  font-size: 3rem;
  font-weight: 600;
}

.contact-section .left-column hr,
.contact-section .right-column hr {
  width: 130px;
  height: 2px;
  background-color: black;
  text-align: left;
  align-items: start;
  margin: 0;
}

.sell-column h4 {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 3.5rem;
  color: white;
  text-align: left;
  justify-content: start;
  align-items: left;
  margin-bottom: 20px;
}

.faq-sell-section hr {
  width: 80px;
  height: 2px;
  background-color: white;
  text-align: left;
  align-items: start;
  margin: 0;
}

h4 {
  font-family: 'Inter';
  font-weight: 300;
  font-size: 3.5rem;
  text-align: left;
  margin-bottom: 20px;
}

.contact-section hr {
  width: 130px;
  height: 3px;
  background-color: black;
  text-align: left;
  align-items: start;
}

.contact-section h2 {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 4rem;
  color: black;
  text-align: center;
  justify-content: start;
  align-items: left;
  margin-bottom: 20px;
}

hr {
  width: 60px;
  height: 5px;
  background-color: black;
}

a {
  color: #9cdcf8;
}

p {
  font-family: 'Inter';
}

.selling-process {
  flex: 1;
  overflow: hidden; /* Prevent inner scrolling */
}

.partners-text {
  color: #9cdcf8;
  text-shadow: 1px 1px 0px #577999;
}

.selling-process-container .hero-section h3 {
  font-size: 3.5rem;
}

.selling-process-container .hero-section button {
  margin-top: 3.5rem;
  font-size: 2rem;
  max-width: 450px;
  padding: 22px 35px;
  background-color: #9cdcf8;
  color: black;
  font-family: 'Inter';
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 200;
}

.selling-process-container .hero-section button:hover {
  background-color: #7bb7e0;
}

.hero-section {
  position: relative;
  background-size: cover;
  background-position: center;
  color: white;
  padding: 3.5rem;
  text-align: center;
}

@media (max-width: 1024px) {
  .hero section {
    position: relative;
    background-size: cover;
    background-position: center;
    color: white;
    padding: 24px;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .fullwidth-video-container video {

    width:100%!important;
    padding:0px!important;


  }
  

  


}



.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Overlay with 50% opacity */
  z-index: 1;
}

.hero-content {
  position: relative;
  z-index: 2; /* Ensure the content is above the overlay */
  text-align: center;
  padding: 20px;
}

.hero-content p {
  margin-bottom: 2rem; /* Add spacing below the paragraph */
}

.selling-process-container .hero-section button {
  margin-top: 3.5rem; /* Ensure there's sufficient margin above the button */
  font-size: 2rem;
  max-width: 450px;
  padding: 22px 35px;
  background-color: #9cdcf8;
  color: black;
  font-family: 'Inter';
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 200;
}


.list-button {
  font-size: 2rem;
  max-width: 100%;
  padding: 22px 35px;
  background-color: #9cdcf8;
  color: black;
  font-family: 'Inter';
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 200;
  text-decoration: none;
}

.list-button:hover {
  background-color: #8ccbe6;
}

.list-button:disabled {
  background-color: #d3d3d3;
  color: #808080;
  cursor: not-allowed;
}

.hero-section .small-text {
  font-size: 1.4rem;
}

/* Google Reviews Section */
.selling-process-container .google-reviews {
  text-align: center;
  padding: 2rem;
  background-color: #fff;
}

/* Two-Column Section Styles */
.selling-process-container .two-column-section,
.selling-process-container .new-two-column-section {
  display: flex;
  padding: 5rem;
  align-items: stretch; /* Ensure columns are the same height */
  background-color: #edf8fd; /* Add a background color if needed */
}

.selling-process-container .left-column,
.selling-process-container .right-column,
.selling-process-container .new-left-column,
.selling-process-container .new-right-column {
  flex: 1;
  background-color: white; /* Add background color to ensure alignment */
}

.left-column {
  background-color: #e5eef1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.right-column {
  background-color: #272727;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  padding: 0px;
}

.right-column-form {
  background-color: #fff;
  display: flex;
  align-items: left;
  justify-content: left;
  flex-direction: column;
}

.new-left-column {
  padding: 0rem;
  text-align: left;
}

.selling-process-container .left-column h2,
.selling-process-container .new-left-column h2 {
  font-size: 4rem;
  line-height: 4.5rem;
  margin-bottom: 1rem;
  text-align: left;
  margin-top: 0rem;
}

.selling-process-container .right-column h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: black;
}

.selling-process-container .left-column .divider {
  width: 100px;
  height: 1px;
  background-color: grey;
  margin: 10px 0;
}

.selling-process-container .left-column p,
.selling-process-container .new-left-column p {
  font-size: 1.75rem;
  margin-bottom: 1rem;
  text-align: left;
  font-family: 'Inter';
  line-height: 3.25rem;
}

.selling-process-container .right-column h3 {
  font-size: 1.75rem;
  margin-bottom: 1rem;
  color: #bfbfbf;
}

.contact-section .right-column h3 {
  font-size: 1.75rem;
  margin-bottom: 1rem;
  color: black;
  line-height: 3.25rem;
}

.contact-section .right-column hr {
  width: 130px;
  height: 2px;
  background-color: black;
  text-align: left;
  margin-bottom: 2rem;
}

.contact-section .right-column {
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: start;
}

.sell-page .video-container {
  flex: 1; /* Make the video container grow */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensure it fills the entire height of the column */
}

.sell-page .video-container video {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
  margin: auto; /* Center the video vertically and horizontally */
}

.we-sell-you-save .video-container {
  flex: 1; /* Make the video container grow */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensure it fills the entire height of the column */
}

.we-sell-you-save .video-container video {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
  margin: auto; /* Center the video vertically and horizontally */
}

/* Testimonial Slider Section */
.testimonial-slider-wrapper {
  text-align: center;
  padding: 2rem 0;
}

.testimonial-slider-section {
  padding: 5rem;
  background-color: #fff;
  position: relative;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1450px) {
  .testimonial-slider-section {
    padding: 0;
    background-color: #fff;
    position: relative;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
}

.testimonial-slider-container {
  position: relative;
  max-width: 100%;
  margin: auto;
  overflow: hidden;
}

.testimonial-slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.testimonial-slide {
  min-width: 100%;
  box-sizing: border-box;
  padding: 1rem;
  text-align: center;
  height: 800px; /* Adjust height to 500px */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  background-size: cover;
  background-position: center;
}

.play-button {
  width: 80px;
  height: 80px;
  background-color: #8ad7f9;
  border-radius: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
}

.play-button::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 30px solid black;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
}

.calculator-section h2 {
  margin: 2rem;
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.lightbox-content {
  position: relative;
}

.lightbox-content iframe {
  width: 800px;
  height: 500px;
  border-radius: 10px;
}

.lightbox-content::after {
  content: '✖';
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: white;
  cursor: pointer;
}

.testimonial-slider-prev,
.testimonial-slider-next {
  cursor: pointer;
  top: 50%;
  width: 40px;
  padding: 10px;
  background-color: #8ad7f9;
  color: black;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border: none;
  border-radius: 50%;
  user-select: none;
  transform: translateY(-50%); /* Ensure the arrows are vertically centered */
  z-index: 99;
  position: absolute;
}

.testimonial-slider-prev:hover,
.testimonial-slider-next:hover {
  color: black;
}

.testimonial-slider-prev {
  left: 20px;
}

.testimonial-slider-next {
  right: 20px;
}

.testimonial-slider-dots {
  text-align: center;
  padding: 1rem;
}

.testimonial-dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #8ad7f9;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.testimonial-dot.testimonial-active {
  background-color: black;
}

.testimonial-dot:hover {
  background-color: #717171;
}

/* Selling Process Slider Section */
.selling-process-slider-wrapper {
  text-align: center;
  padding: 2rem 0;
}

.selling-process-slider-wrapper h2 {
  font-size: 4rem;
  line-height: 4.5rem;
  margin-bottom: 1rem;
}

.slider-divider {
  width: 100px;
  height: 2px;
  background-color: grey;
  margin: 10px auto 20px;
}

.selling-process-slider-section {
  padding: 5rem;
  background-color: #fff;
}

/* Adjustments to ensure correct alignment and spacing */
.selling-process-slider-container {
  position: relative;
  display: flex;
  align-items: center;
}

.selling-process-slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%; /* Ensure slides take full width */
}

.selling-process-slider-container {
  position: relative;
  max-width: 100%;
  margin: auto;
  overflow: hidden;
}

.selling-process-slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.selling-process-slide {
  min-width: 100%;
  box-sizing: border-box;
  padding: 1rem;
  text-align: center;
}

.selling-process-slide img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.selling-process-slide h3 {
  font-size: 2.5rem;
  margin-top: 1rem;
}

.selling-process-slide p {
  font-size: 1.75rem;
  line-height: 2.5rem;
  font-family: 'Inter';
  margin-top: 0.5rem;
}

.selling-process-prev,
.selling-process-next {
  cursor: pointer;
  background-color: transparent;
  border: none;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  color: black; /* Change arrow color to black */
  font-weight: bold;
  font-size: 24px;
  transition: 0.6s ease;
  border-radius: 50%;
  user-select: none;
  transform: translateY(-50%); /* Ensure the arrows are vertically centered */
  z-index: 99;
  margin: 0 20px;
}

.selling-process-prev:hover,
.selling-process-next:hover {
  color: black;
}

.selling-process-prev {
  left: 20px; /* Adjust position to bring closer to the center */
}

.selling-process-next {
  right: 20px; /* Adjust position to bring closer to the center */
}

.selling-process-dots {
  text-align: center;
  padding: 1rem;
}

.selling-process-dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.selling-process-dot.selling-process-active {
  background-color: #9cdcf8;
}

.selling-process-dot:hover {
  background-color: #717171;
}

/* Marketing Advantages Section */
.marketing-advantages {
  text-align: center;
  padding: 2rem 0;
}

.circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15vw;
  width: 15vw;
  border-radius: 50%;
  background-color: #8ed9f8;
  color: #252525;
  font-family: 'Inter', serif;
  text-align: center;
  margin: 0 auto;
  font-weight: 500;
  font-size: 1.2rem;
  position: relative;
  transition: all 0.3s ease;
  z-index: 1;
  border: 0.1rem solid black;
  margin-top: 32rem;
  margin-bottom: 32rem;
}

@media screen and (max-width: 1024px) {
  .circle-container {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }
}

@media screen and (max-width: 768px) {
  .circle-container {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }
}

.circle-text {
  padding: 1rem;
  max-width: 80%;
  word-wrap: break-word;
}

.extra-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vw;
  width: 10vw;
  border-radius: 50%;
  background-color: #8ed9f8;
  color: #252525;
  font-family: 'Inter', serif;
  text-align: center;
  position: absolute;
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s ease;
  border: 0.1rem solid black;
  z-index: 0;
  font-size: 1rem;
  padding: 0.5rem;
}

.circle-container:hover .extra-circle {
  opacity: 1;
  transform: scale(1) translate(var(--x), var(--y));
}

.extra-circle:hover {
  z-index: 2;
}

.extra-circle:nth-child(2) {
  transition-delay: 0.1s;
}
.extra-circle:nth-child(3) {
  transition-delay: 0.2s;
}
.extra-circle:nth-child(4) {
  transition-delay: 0.3s;
}
.extra-circle:nth-child(5) {
  transition-delay: 0.4s;
}
.extra-circle:nth-child(6) {
  transition-delay: 0.5s;
}
.extra-circle:nth-child(7) {
  transition-delay: 0.6s;
}
.extra-circle:nth-child(8) {
  transition-delay: 0.7s;
}
.extra-circle:nth-child(9) {
  transition-delay: 0.8s;
}
.extra-circle:nth-child(10) {
  transition-delay: 0.9s;
}
.extra-circle:nth-child(11) {
  transition-delay: 1s;
}

.expanding-ring-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.expanding-ring {
  position: absolute;
  width: 20vmax;
  height: 20vmax;
  border-radius: 100%;
  border: 0.1rem solid #a1e0f7;
  animation: expand-outward 6s ease-out infinite;
}

@keyframes expand-outward {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  33% {
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.expanding-ring:nth-child(1) {
  animation-delay: 0s;
}
.expanding-ring:nth-child(2) {
  animation-delay: 2s;
}
.expanding-ring:nth-child(3) {
  animation-delay: 4s;
}

@media (max-width: 800px) {
  .extra-circle {
    height: 16vw !important;
    width: 16vw !important;
    font-size: 0.5rem !important;
  }

  .circle-container {
    height: 25vw !important;
    width: 25vw !important;
    font-size: 0.6rem !important;
  }
}

/* Accordion Styles */
.accordion {
  margin-top: 1rem;
}

.accordion-item {
  border-bottom: 1px solid #ddd;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
}

.sell-page .google-reviews-section h2 {
  text-align: center;
  font-family: 'Inter';
  color: #000;
  font-size: 2.5rem;
  margin-top: 0px;
  padding-bottom: 15px;
  margin-bottom:0px;
}

.accordion-header {
  display: flex;
  align-items: center;
  width: 100%; /* Ensure header spans full width */
}

.accordion-icon {
  margin-right: 1rem;
  font-size: 3rem; /* Increase the icon size */
}

.accordion-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  flex-grow: 1; /* This makes the text take up all available space */
  margin-right: 1rem; /* Adds some space between the text and the arrow */
  font-size: 24px;
  text-align: left; /* Left-align the text */
  font-family: 'Inter';
}

.accordion-arrow {
  font-size: 1.5rem;
  background-color: #9cdcf8;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 20px;
  color: #000; /* White chevron */
  font-size: 1.5rem; /* Adjust size as needed */
}

.accordion-content {
  margin-top: 1rem;
  padding-left: 1rem;
  padding: 1rem 0;
  width: 100%; /* Ensure it spans the full width */
  box-sizing: border-box; /* Include padding and border in the element's total width */
  font-family: 'Inter';
  font-size: 24px;
  text-align: left;
}

/* Full Width Section */
.full-width-section {
  background-color: white;
  padding: 0px;
  text-align: center;
  
  margin:15px;
  margin-left:0px;
}

.full-width-section h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.sell-page .full-width-section {min-height:200px!important;padding-top:0px;margin-top:0px;}

.we-sell-you-save p {
  font-size: 1.75rem;
  margin-bottom: 1rem;
  text-align: left;
  line-height: 3.25rem;
}

@media (max-width:1550px) {
  .sell-page .full-width-text-section {
    max-width: 1500px;
    margin-left: 15px!important;
    margin-right: 15px!important;
    margin-bottom: 15px!important;
    padding: 0rem 0rem;
}
}

.sell-page .full-width-section h2 {
  font-size: 2.5rem;
  padding-top:0px;
  margin-bottom:40px;
  margin-top:40px;
}

.we-sell-you-save .divider {
  width: 100px;
  height: 1px;
  background-color: grey;
  margin: 10px 0;
}

#map-container {
  position: relative;
  margin: 0;
  width: 50%; /* Adjusted to fit alongside another chart */
}

#overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(142, 217, 248, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 98;
  transition: opacity 0.5s ease;
  opacity: 1;
  pointer-events: auto;
}

.overlay-content {
  text-align: center;
  color: black;
  font-family: 'Inter', sans-serif;
  line-height: 30px;
  max-width: 180px;
  font-weight: 700;
  text-shadow: 0px 0px 30px #5c8ca1;
}

.overlay-text {
  font-size: 24px;
}

/* Hide overlay when mouse over */
#map-container:hover #overlay {
  opacity: 0;
  pointer-events: none;
}

/* Home Price Chart */
#homePriceChart {
  width: 50%; /* Adjusted to fit alongside another chart */
  margin: auto;
}

/* Featured Listings Section */
.featured-listings {
  padding: 2rem;
  text-align: center;
  background-color: #f9f9f9;
}

.featured-listings h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.listings-grid {
  display: flex;
  justify-content: space-between;
}

.listing {
  flex: 1;
  margin: 0 1rem;
  text-align: center;
}

.listing img {
  width: 100%;
  border-radius: 10px;
}

.listing p {
  margin: 0.5rem 0;
}

/* Contact Section */
.contact-section {
  padding: 2rem;
  text-align: center;
}

.slider-next {
  width: 100px;
}

.contact-section p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

/* Flexbox Container for Charts */
.charts-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.selling-process-slides h3 {
  color: #9cdcf8;
}



.contact-row .left-column {
  padding-top: 0;
}

.contact-form-title {
  font-size: 3.5rem !important;
  line-height: 3.7rem;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .hero-section h1 {
    font-size: 3rem;
    line-height: 4rem;
  }

  .hero-section p {
    font-size: 1.5rem;
    line-height: 2.5rem;
  }

  .hero-section .small-text {
    font-size: 1rem;
  }

  .counter h2 {
    font-size: 4rem;
  }

  .counter p {
    font-size: 2rem;
  }

  .new-two-column-section {
    flex-direction: column;
    padding: 0rem;
  }

  .left-column,
  .right-column,
  .new-right-column {
    padding: 2rem;
  }

  .conditions-section {
    padding: 2rem;
  }

  .new-left-column {
    padding: 2rem;
  }

  .we-sell-you-save .left-column {
    padding: 2rem;
  }

  .offer-section {
    padding: 2rem;
  }
  .accordion-title {
    font-size: 1.5rem;
  }

  .accordion-icon {
    font-size: 2rem;
  }

  .accordion-arrow {
    width: 20px;
    height: 20px;
    font-size: 1rem;
  }

  .accordion-content {
    font-size: 1.5rem;
  }

  .contact-form-title {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

.two-column-section .left-column {
  background-color: #fff;
}

.two-column-section .right-column {
  background-color: #272727;
}

.two-column-section .new-left-column {
  flex: 1;
  text-align: left;
}

.two-column-section .left-column h2,
.new-left-column h2 {
  font-size: 3.5rem;
  line-height: 4rem;
  margin-bottom: 1rem;
  text-align: left;
  margin-top: 0rem;
  margin-right: auto;
}

.two-column-section .left-column h3,
.new-left-column p {
  font-size: 1.75rem;
  margin-bottom: 1rem;
  text-align: left;
  line-height: 3.25rem;
}

.two-column-section .right-column h3 {
  font-size: 1.75rem;
  line-height: 3rem;
  margin-bottom: 1rem;
  color: #bfbfbf;
}

.two-column-section .accordion {
  margin-top: 1rem;
}

.two-column-section .accordion-item {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  border-bottom: 1px solid #ddd;
}

.two-column-section .accordion-header {
  display: flex;
  align-items: center;
  width: 100%; /* Ensure header spans full width */
}

.two-column-section .accordion-icon {
  margin-right: 1rem;
  font-size: 3rem; /* Increase the icon size */
}

.two-column-section .accordion-icon img {
  margin-right: 1rem;
  font-size: 3rem; /* Increase the icon size */
  width: 70px;
  fill: white;
  color: white;
}

.two-column-section .accordion-title {
  flex-grow: 1;
  text-align: left; /* Left-align the text */
  font-family: 'Inter';
  font-size: 2rem;
}

.two-column-section .contact-form-title {
  font-size: 3rem !important;
  line-height: 3.5rem;
}

.two-column-section .right-column h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: white;
}

.two-column-section .right-column {
  display: block;
  padding: 30px;
}

.local-realtor-expert {
  flex-direction: inherit;
  display: flex;
  align-items: stretch;
  padding: 5rem;
}

@media (max-width: 991px) {
  .local-realtor-expert {
    flex-direction: column;
    display: flex;
    align-items: stretch;
    padding: 2rem;
  }
}

.contact-row {
  flex-direction: inherit;
  display: flex;
  align-items: stretch;
}

.we-sell-you-save {
  flex-direction: inherit;
  display: flex;
  align-items: stretch;
  background-color: white;
}

@media (max-width: 991px) {
  .we-sell-you-save {
    flex-direction: column;
    display: flex;
    align-items: stretch;
    background-color: white;
  }
}

.we-sell-you-save .left-column {
  background-color: white;
  padding: 5.5rem;
}

@media (max-width: 768px) {
  .we-sell-you-save .left-column {
    padding: 2rem;
  }
}

.we-sell-you-save .right-column {
  background-color: white;
}

@media (max-width: 991px) {
  .two-column-section .left-column h2,
  .new-left-column h2 {
    font-size: 3rem;
    line-height: 3.5rem;
  }
  .two-column-section .accordion-icon {
    display: none;
  }

  .selling-process-slider-section {
    padding: 2rem;
  }
}

#map-container {
  height: unset !important;
}

.sell-page .video-container {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left:15px;
  padding-right:15px;
  padding-bottom:15px;
  margin-bottom:15px;

  background-color: #F3F3F3;

}

.sell-page .video-container {
  position: relative;
  aspect-ratio: auto;
  max-width: 100%;
  padding-bottom: 11px !important;
}

.sell-page .sell-video-container {
  margin-bottom: 5rem;
}

.sell-page .steps-container {
  justify-content: center;
  display: grid;
}

.sell-page .content-image-section {
  max-width: 1500px;
        background-color: #F3F3F3;
        border-radius: 5px !important;
        padding: 30px;
        margin-left: 15px;
        margin-right: 15px;
}

.sell-page .google-reviews-section {
  max-width: 100%;
}

.sell-page .counter-box img {
  width: 80px;
  height: 80px;
}

.sell-page .google-reviews-section .review-text {
  font-size: 1.5rem !important;
  line-height: 2.75rem !important;
}

.sell-page .review-card {
  box-shadow: 0px 0px 0px;
  border-width: 0px;
  padding: 3rem;
}

.sell-page .google-reviews-section {
  padding: 40px;
  background-color: #f3f3f3;
  margin-top:0;
}

.sell-page .full-width-text-section h2 {
  text-align: left;
    font-family: 'Inter';
    color: #000;
    font-size: 2.5rem;
    margin-top: 40px;
    margin-bottom: 40px;
}



.sell-page .featured-listings-heading {
  font-family: 'Inter';
  font-size: 2.5rem;
  font-weight: 700;
  padding-top: 30px;
  padding-bottom: 15px;
  margin-top: 0px;
  color: #000;
  background-color: #F3F3F3;
  margin-bottom:0px;
  text-align:center;
}

.sell-page .custom-city-container h2 {
  font-family: 'Inter';
  font-size: 2.5rem;
  font-weight: 700;
  margin-top: 0px;
  padding: 20px;
  padding-top: 30px;
  color: #000;
  text-align:center;
}

.sell-page .seo-cities h2 {
  text-align: left;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 1.3rem;
  color: #000;
  font-family: 'Inter';
}

.sell-page .toronto-star-heading {
  font-size: 2.5rem;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 0px;
  color:black;
}

.sell-page .full-width-text-section {
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;;
  padding:0rem 0rem;
}

.sell-page .counter-section h2 {
  text-align: left;
  font-family: 'Inter';
  color: #000;
  font-size: 2.5rem;
  margin-top: 0px;
  padding-bottom: 15px;
}

.sell-page .full-width-text-section p {
  font-size: 1.5rem;
  color: #000;
  line-height: 2.5rem;
  font-family: 'Inter';
  text-align:left;
}

.sell-page h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: left;
  font-family: 'Inter';
  color: #1b478c;
}

.sell-page .graph-heading {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-align: center;
  font-family: 'Inter';
  color: #1b478c;
}

.sell-page .calculator-section h2 {
  text-align: center;
  font-family: 'Inter';
  color: #000;
  font-size: 2.5rem;
  margin-top: 0rem;
  margin-bottom: 0rem;}

.sell-page .content-image-section h2 {color:#1b478c;}

.sell-page .video-container {margin-bottom:0;}

.sell-page .google-reviews {
  background-color: #f3f3f3;
}

.sell-page .center-slide .review-card {
  background-color: white !important;
  border-width: 0px;
  padding: 3rem;
}

.sell-page .review-author {
  font-size: 1.25rem !important;
}






.sell-page .unique-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  cursor: pointer;
  background-color: #9cdcf8;
  padding: 20px;
  border-radius: 50%;
}

.sell-page .commission-calculator-section {
  padding: 5rem 0rem;
}

.sell-page .unique-play-icon {
  width: 42.5px;
  height: 40px;
  color: white;
}

.sell-page .seo-cities h2 {
  margin-bottom: 15px;
  margin-top: 15px;
}

.sell-page .reviews-read-more-button {
  background: none;
  color: #4285F4;
  border: none;
  font-size: 1.5rem;
  padding: 10px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.5rem;
}

.sell-page .unique-play-icon:hover {
  opacity: 0.8;
}

.sell-page-content {
  padding: 2rem;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}



.sell-page .video-container video {border-radius:5px;}

.sell-page .image-content img {border-radius:15px;}

/* Buttons Section */
.sell-page .buttons-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #9cdcf8;
  padding: 15px;
  border-radius: 5px;
  margin: 20px 10px;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
}


.sell-page .counter-section {
  
  background-color: white;
}

@media (max-width: 768px) {
  .sell-page .full-width-text-section {
    padding-left: 0;
    padding-right: 0;
  }

  .sell-page .content-image-section {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sell-page .content-image-section h2 {
    text-align: center;
  }

  .sell-page .content-image-section p {
    text-align: center !important;
    font-size: 1rem !important;
      color: #000;
      line-height: 2.5rem;
      font-family: 'Inter';
  }

  .sell-page .content-image-section.text-right .text-content h2 {text-align:center;}
}

.sell-page .home-page-lets-talk {
  width: unset;
  background-color: white;
  text-align: center;
  padding: 4rem 2rem;
  margin: 2rem 10px;
  border-radius: 15px;
  margin-left:auto;
  margin-right:auto;
}

.content-image-section h2 {text-align:left;}


.sell-page .home-page-lets-talk h2 {color:white;}

.sell-page .home-page-toronto-star {margin:10px;}

.sell-page .home-page-map-section {margin:5rem 2rem;}

.sell-page .home-page-toronto-star {box-shadow:unset;margin-left:auto;margin-right:auto;}

.sell-page .contact-form {margin:1rem;width:90%; max-width:1500px; margin-left:auto!important;margin-right:auto!important;}

.sell-page .contact-form input,.sell-page .contact-form textarea {border-width:0px; background-color:#f3f3f3;padding:15px;}

.sell-page .checkbox-container label {color:black;font-size:20px;}

@media (max-width:768px) {
  .sell-page .full-width-text-section h2 {
    text-align: left;
      font-family: 'Inter';
      color: #000;
      font-size: 2rem;
      margin-top: 40px;
      margin-bottom: 40px;
  }

  .sell-page .full-width-text-section p {
    font-size: 1.5rem;
    color: #000;
    line-height: 2.5rem;
    font-family: 'Inter';
    text-align: left;
}
}

@media (max-width: 768px) {
  .sell-page .full-width-text-section p {
      font-size: 1rem!important;;
      color: #000;
      line-height: 2.5rem;
      font-family: 'Inter';
  }
  .sell-page .calculator-section h2 {
    text-align: center;
    font-family: 'Inter';
    color: #000;
    font-size: 2rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
}

}

