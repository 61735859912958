/* Additional styling for the popup image slider */
/* Additional styling for the popup image slider */

.pagination-ellipsis {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  font-size: 1.2rem;
  line-height: 1;
  position: relative;
  top: 2px;
  color: #666;
  font-weight: bold;
}

.filter-item .search-filter .mobile-only {background-color:white!important;border-radius:5px;}

.filter-item.search-filter.mobile-only {background-color:white!important;border-radius:5px;margin-bottom:15px;width:100%;text-align:left;text-decoration: none;}

#root > div > div > div.filter-item.search-filter.mobile-only > label {
  text-decoration: none!important;
  margin-bottom: 5px;
  padding-bottom:15px!important;
  color: black;
  font-family: Inter;
  font-weight: 200;
  text-align: left;
}

#root > div > div > div.filter-item.search-filter.mobile-only > div > input {margin-top:10px;}

/* Hide the search bar on desktop */
.search-filter.mobile-only {
  display: none;
}

/* Show the search bar only on mobile */
@media (max-width: 1070px) {
  .search-filter.mobile-only {
    display: block;
  }
}



.pagination-ellipsis::before {
  content: "...";
  font-weight: bold;
  font-size: 1.2rem; /* Matches button font size */
  vertical-align: bottom;
}


.popup-image-slider img {
  width: 100%;
  height: 600px;
  object-fit: contain;
}

.listing-image.blurred {
  filter: blur(8px);
  pointer-events: none!important;
}

.listing-content.blurred {
  filter: blur(8px);
  pointer-events: none!important;
}

@media (max-width:700px) {
  .listing-search-container .street-address {
    font-size:17px!important;
  }
}

@media (max-width:450px) {
  .listing-search-container .street-address {
    font-size:22px!important;
  }
}

.popup-content .bottom-bar {display:none;}

.listing-search-container .main-content {background-color:white;padding-top:25px;border-radius:5px;justify-content: center; align-items: center;max-width:1500px;width:100%;}

.listing-search-container .listing-detail-container .main-content {background-color:white;padding-top:25px;border-radius:5px;max-width: none;justify-content: start; align-items: start;}


.highlight {
  background-color: yellow; /* Highlight background */
  font-weight: bold; /* Make it bold */
  color: black; /* Set the text color */
}

.listing-search-container {
  display: flex;
  flex-direction: column;
  padding-left:15px;
  padding-right:15px;
  padding-bottom:15px;
  background-color: #F3F3F3;
}

.listing-search-container .filter-bar {border-radius:5px;border-width:0px;max-width:1500px;width:100%;}

.popup-content {padding: 3px 0;}

.listing-popup .slick-slide img {height:auto;width:100%;max-width:100%;}

@media (max-width: 768px) {
  .popup-image-slider img {
    width: 100%;
    height:auto;
    object-fit: contain; /* Adjust as necessary for mobile responsiveness */
    transform: scale(0.7); /* Scale down to 70% of the original size */
  }
}

.error-message {display:none;}

/* Existing CSS */

.listing-search-container {
  display: flex;
  flex-direction: column;
}

.filter-bar {
  display: flex;
  justify-content: space-between;
  padding: 0px 00px;
  background-color: #fdfdfd;
  border-bottom: 1px solid #ddd;
}



.filter-item label {
  font-family: 'Inter';
  font-size: 18px;
  color: #555;
  text-align: left;
  font-weight: 800;
}

.filter-item select,
.filter-item input {
  padding: 10px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
}

.price-range-input {
  width: 100%;
  font-size: 16px;
}

.applied-filters-bar {
  display: flex;
  gap: 10px;
  padding: 10px;
  background-color: #fff;
  border-bottom: 0px solid #fff;
  margin-bottom: 10px;
}

.filter-button,
.clear-filters-button {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.remove-filter {
  color: #f00;
  cursor: pointer;
}

.main-content {
  display: flex;
  gap: 20px;
  padding-bottom:20px;
}

.map-column {
  width: 30%;
  height: 80vh;
}

.listings-column {
  width: 100%;
}

.listing-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-left:20px;
}

@media (max-width:1024px) {
  .listing-grid {padding-left:0px;}
}


.listing-card {
  width: calc(25% - 20px); /* 4 columns */
  padding: 5px;
  border-radius: 5px;
  position: relative;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
}



.listing-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
  padding-bottom: 10px;
}

.image-container img {
  border-radius: 0px !important;
}

.listing-card img:hover {
  transform: scale(1.02);
}

/* Blurred content for sold listings */
.listing-card.sold .listing-content {
  filter: blur(5px);
  pointer-events: none;
}

/* Hide blur effect if user is logged in */
.listing-card.sold.user-logged-in .listing-content {
  filter: none;
  pointer-events: all;
}

/* Login button overlay for non-logged-in users */
.login-button-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: black;
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  z-index: 10;
  pointer-events: none;
}

.login-button-overlay a.login-button {
  color: black;
  text-decoration: none;
  font-weight: bold;
}

/* Custom scrollbar for the popup */
.listing-popup {
  overflow-y: scroll;
}

.listing-popup::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

.listing-popup::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the scrollbar track */
}

.listing-popup::-webkit-scrollbar-thumb {
  background-color: #9CDCF8; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the scrollbar thumb */
}

.listing-popup::-webkit-scrollbar-thumb:hover {
  background-color: #7bb7e0; /* Color when hovering over the scrollbar */
}

.listing-style-badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #9CDCF8;
  color: black;
  font-family: 'Inter';
  padding: 8px 15px;
  border-radius: 0px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px; /* Adjust as needed */
}

.login-button {
  color: black;
  text-decoration: none;
  font-weight: bold;
  background-color: #9CDCF8;
  padding: 10px 20px;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  display:block;
  pointer-events:all;
}

.login-button:hover {
  background-color: #9CDCF8;
}

.listing-slider {
  position: relative;
  z-index: 0;
}

.slider-item {
  border-radius: 5px;
}

.listing-title {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.title-community {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.community, .street-address {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: #555;
  margin: 0;
}

.street-address {
  font-size: 18px;
  font-weight: bold;
}

.listing-details {
  display: flex;
  justify-content: left;
  margin: 0px 0px 10px 0px;
  gap: 6px;
  font-family: 'Inter', serif;
  font-size: 16px;
}

.listing-detail-item {
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius:0px;
}

.icon {
  width: 24px; /* Adjust the size as needed */
  height: 24px; /* Adjust the size as needed */
}

.listing-price {
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  color: #000;
  text-align: right;
}

.separator-dot {
  margin: 0 5px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination-button {
  margin: 0 5px;
  padding: 10px 15px;
  border: none;
  background-color: #f0f0f0;
  color: #333;
  cursor: pointer;
}

.pagination-button.active {
  background-color: #9cdcf8;
  color: black!important;
}

.pagination-button:hover {
  color: #fff;
}

a:-webkit-any-link {
  color: inherit;
  text-decoration: none;
}

.listing-card a {
  color: inherit;
  text-decoration: none;
  font-family:'Inter';
}

.listing-card a:hover {
  text-decoration: none;
}

.slick-next:hover, .slick-prev:hover {
  background-color: transparent;
}

@media (max-width: 1300px) {
  .listing-card {
    width: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
  }

  .map-column {
    display: none;
  }

  .listings-column {
    width: 100%;
  }

  .listing-card {
    width: calc(50% - 20px);
  }

  .filter-bar {
    flex-direction: column;
    gap: 10px;
  }

  .filter-item {
    margin-right: 0;
  }

  .filter-item.price-range-filter {
    width: 100%;
    padding-right:20px;
  }

  .filter-item:not(.price-range-filter) {
    flex: 1;
  }

  .filter-bar .filter-item:nth-child(2),
  .filter-bar .filter-item:nth-child(3) {
    flex: 1;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  .filter-bar .filter-item:nth-child(4),
  .filter-bar .filter-item:nth-child(5) {
    flex: 1;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  .filter-bar .filter-item:nth-child(2) select,
  .filter-bar .filter-item:nth-child(3) select,
  .filter-bar .filter-item:nth-child(4) select,
  .filter-bar .filter-item:nth-child(5) input {
    width: 100%;
  }

  .listing-popup .listing-slider-container {
    max-height: 250px;
    overflow: hidden;
  }

  .listing-details {
    flex-wrap: wrap;
  }
}

@media (max-width: 480px) {
  .listing-card {
    width: 100%;
  }
}

/* Styles for the popup */
.listing-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 0px;
  z-index: 1000;
  max-width: 1800px;
  width:100%;
  max-height: 80%;
  overflow-y: auto;
}



.listing-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Additional styles for search input and icon */
.search-filter {
  position: relative;
}

.search-input {
  padding-left: 30px;
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  font-size: 18px;
  color: #888;
}

.sold-listing-link {
  display: block;
  position: relative;
  text-decoration: none;
  color: inherit;
}

.sold-listing-link::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  z-index: 1;
}

.sold-listing-link .login-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: black;
}

/* Hide blur effect if user is logged in */
.listing-card.sold.user-logged-in::before {
  background: none;
  backdrop-filter: none;
  display: none;
}

.listing-card.sold.user-logged-in .login-button {
  display: none;
}

/* Add this to the existing styles in Listings.css */
.wishlist-icon-container {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}

.wishlist-icon {
  font-size: 1.5rem;
  color: #000; /* Black outline for non-active heart */
  cursor: pointer;
  transition: color 0.3s ease;
}

.wishlist-icon.active {
  color: #9CDCF8; /* Filled blue heart for active state */
}

.wishlist-icon:hover {
  color: #9CDCF8; /* Change to desired hover color */
}

/* Add pop animation */
@keyframes pop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.pop-animation {
  animation: pop 0.3s ease;
}

/* Ensure the arrows and dots are visible and clickable */
.slick-arrow,
.slick-dots {
  z-index: 2; /* Ensure they are above other elements */
}

/* Ensure the popup overlay has a higher z-index */
.listing-popup-overlay {
  z-index: 999;
}

/* Ensure the popup has a higher z-index than the arrows */
.listing-popup {
  z-index: 1000;
}

/* Additional styling to ensure the arrows are visible when the user is logged in */
.listing-card.sold.user-logged-in .slick-arrow,
.listing-card.sold.user-logged-in .slick-dots {
  display: block !important;
}

/* Placeholder for the map */
.map-placeholder {
  width: 100%;
  height: 100%;
  background-color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #555;
  font-family: 'Inter', sans-serif;
}

.listings-count {
  text-align: center;
  margin: 10px 0;
  font-size: 14px;
  color: #555;
  font-family: 'Inter', sans-serif;
}

.listing-card.sold .listing-content {
  filter: blur(5px);
  pointer-events: none;
}

.listing-card.sold.user-logged-in .listing-content {
  filter: none;
  pointer-events: all;
}

/* Apply blur to the entire card content */
.listing-card.blurred .blur-effect {
  filter: blur(5px)!important;
  pointer-events: none!important;
}

.listing-card.blurred {
  pointer-events:none!important;
}

.login-button-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: black;
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  z-index: 10;
  pointer-events: all;
}

.login-button-overlay a.login-button {
  color: black;
  text-decoration: none;
  font-weight: bold;
}

.listing-search-container .listings-column .slick-dots ul {padding:0px;}

.listing-search-container .street-address {font-family:'Inter';color:black;font-weight:200;font-size:22px;}

.listing-search-container .community {color:#5f5e5e;font-family:'Inter';}

.listing-search-container .listing-detail-item {font-family:Inter;background-color:#f7f7f7; border-radius:0px; padding:5px; border:0px solid rgb(202, 202, 202);}

.listing-search-container .listing-title {padding:0px 10px;}

.listing-search-container .listing-price {font-weight:200; font-size:22px;}
.listing-search-container .icon {width:30px;}

.listing-search-container .input-range__label {color:black;font-family:'Inter';font-size:14px;}

.listing-search-container .filter-item label {text-decoration:underline;margin-bottom:5px;color:black;font-family:Inter;font-weight:200;}

.price-range-filter .input-range {width:80%;margin-left:10px;}

.listings-column .listing-title {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  flex-wrap: nowrap; /* Prevent wrapping */
}

.listings-column .title-community {
  flex-grow: 1; /* Allow the address/community to take up available space */
  margin-right: 20px; /* Add some spacing between the community and price */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis if the text is too long */
}

.listings-column .listing-price {
  margin-top:15px;
  flex-shrink: 0; /* Prevent the price from shrinking */
  font-weight: 600; /* Make the price stand out */
  font-size: 20px; /* Adjust the font size as needed */
  text-align: left;
  white-space: nowrap; /* Prevent price from wrapping */
  font-family:'Inter';
}

.listings-column .custom-dot {box-shadow:0px 0px 30px #00000021;border:1px solid black;}

.listing-style-badge a {font-family:'Inter';}

.listing-popup .custom-listing-container {
  display:none;
}

@media (max-width:1024px) {
  .popup-content {
    padding:10px 5px!important;
  }
}

.price-inputs {
  display: flex;
  gap: 10px;
  align-items: center;
}

.price-input {
  width: 100px;
  padding: 10px;
  font-size: 14px;
  box-sizing: border-box;
  border: 1px solid #777777;
  border-radius: 2px;
}

.price-inputs span {
  font-size: 18px;
  font-family: 'Inter';
  color: #555;
}

.listing-image {
  transition: filter 0.3s ease-in-out;
}

.listing-image.blurred {
  filter: blur(8px);
}



.popup-content .listing-info a {background-color:#9CDCF8; border-radius:5px;padding:8px 15px; font-family:'Inter';border:1px solid black;}

/* Close button styles */
.popup-close-button {
  position: absolute;
  top: 15px;
  right: 10px;
  color: black;
  border: none;
  border-radius: 3px;
  width: 30px;
  height: 30px;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  line-height: 30px;
  font-size:18px;
  font-weight:400;
  margin-top:10px;
  background-color:white!important;
}

/* Popup content columns */
.popup-content {
  display: block;
  gap: 20px;
  padding: 3px 0;
  overflow-x:hidden;
}

.popup-content img {max-width:800px;}

.popup-left-column,
.popup-right-column {
  flex: 1;
}

.popup-left-column .listing-info {
  margin-top: 20px;
}

.popup-right-column {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.popup-right-column h4 {
  margin-bottom: 15px;
  font-family: 'Inter';
}

.popup-right-column p {
  margin: 10px 0;
  font-family: 'Inter';
  font-size: 16px;
  color: #333;
}

.popup-content .slick-list {max-width:850px;width:100%;}

.popup-content .slick-dots ul {padding:0px;}

.popup-content .listing-info p {font-family:'Inter';}

.transition-container {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.transition-container.loading {
  opacity: 0;
}

.popup-content .slick-slide img {
  max-height:700px!important;
}

/* Keyframes for slanted grey flash animation */
@keyframes slanted-flash {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

/* Loading card styles */
.listing-card.loading-card {
  background: linear-gradient(120deg, #e0e0e0 25%, #f8f8f8 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: slanted-flash 1.5s infinite ease-in-out;
  border-radius: 5px;
  height: 200px; /* Adjust the height as needed */
  position: relative;
  overflow: hidden;
}

/* Ensure that the loading-card retains the dimensions and spacing of a normal card */
.listing-card.loading-card::before {
  content: "";
  display: block;
  padding-bottom: 100%; /* Adjust based on the aspect ratio */
}

/* Wrapper for the sticky behavior */
.share-popup-wrapper {
  position: relative; /* Wrapper around the share popup */
  z-index: 1001; /* Ensure it's above other elements */
}

/* Share Popup Styles */
.share-popup {
  position: sticky; /* Keeps the share popup in view when scrolling */
  top: 100px; /* Adjust this value to move the popup down the screen */
  margin: 0 auto; /* Center the popup horizontally */
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 400px;
  width: 90%; /* Responsive width */
  z-index: 1001; /* Ensure it's above other elements */
}

.banner-left {padding:10px;border-radius:0px;border-width:1px!important;background-color:#F3FAFD;border:solid;}

.banner-icon {font-size:18px!important;padding-left:5px;padding-top:0px;color:#333;}

.share-link {font-size:17px;font-family:'Inter';display:flex;}

.share-popup-content {
  text-align: center;
}

.share-popup-content h4 {
  margin-bottom: 20px;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  color: #333;
}

.share-popup-content input.share-link-input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #f9f9f9;
}

.share-popup-content input.share-link-input:focus {
  outline: none;
  border-color: #9cdcf8;
}

.share-popup-content .copy-link-button {
  margin-top: 15px;
  background-color: #9cdcf8;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.share-popup-content .copy-link-button:hover {
  background-color: #7bb7e0;
}

.share-popup-content .copy-link-button:active {
  transform: scale(0.98);
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;
  color: #555;
  cursor: pointer;
}

.close-icon:hover {
  color: #000;
}

/* Overlay to dim the background */
.share-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

@media (max-width:1024px) {
  .listing-history-table td {
    font-size:15px;
  }

  .listing-history-table th {
    font-size:17px;
  }

  .listing-popup {
    width:95%;
    overflow-x:hidden;
  }
}

@media (max-width:768px) {
  #root > div > div.listing-search-container > div.filter-bar > div:nth-child(4) {
    display:none!important;
  }

  #root > div > div.listing-search-container > div.filter-bar > div:nth-child(3)
  {
    display:none!important;
  }

  #root > div > div.listing-search-container > div.filter-bar > div:nth-child(2) {
    display:none!important;
  }

  #root > div > div.listing-search-container > div.filter-bar > div:nth-child(1)
  {
    display:none!important;
  }
}

/* Hide the mobile filter section on larger screens */
.mobile-filter-section {
  display: none;
  padding: 10px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
}

.filter-toggle-btn {
  width: 100%;
  background-color: #FDFDFD;
  color: black;
  padding: 10px;
  border: none;
  text-align: left;
  font-size: 1.2rem;
  cursor: pointer;
}

.mobile-filter-options {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 10px;
}

.mobile-filter-options .filter-item {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1070px) {
  /* Show the mobile filter section on smaller screens */
  .mobile-filter-section {
    display: block;
    background-color: #FDFDFD;
    margin-bottom:20px;
  }

  .main-content {margin-top:20px!important;}

  /* Hide the desktop filter bar on mobile */
  .filter-bar {
    display: none;
  }

  .applied-filters-bar {display:none!important;}
}

.search-input-container {
  position: relative;
  width: 100%; /* Ensure the container takes full width */
}

.search-input {
  width: 100%;
  padding-right: 40px; /* Make space for the search icon */
  box-sizing: border-box; /* Ensure padding is accounted for in width */
}

.search-button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #888;
}

.search-button:hover {
  color: #000; /* Change color on hover */
}

.city-suggestions {
  border: 1px solid #ccc;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  position: absolute;
  width: 100%;
  list-style-type: none;
  margin: 5px 0;
  padding: 0;
  z-index: 10;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.city-suggestions li {
  padding: 15px;  /* Increased padding for larger height */
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-bottom: 1px solid #eee;
}

.city-suggestions li:last-child {
  border-bottom: none;
}

.listing-search-container .image-container {
    width:auto;
    max-height:250px;
    border-radius:5px;
}

@media (max-width:1024px) {
  .listing-search-container .image-container {
    width:auto;
    height:200px;
  }
}

.city-suggestions li:hover {
  background-color: #f7f7f7;
}

.suggestion-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.city-name {
  font-size: 15px;  /* Slightly larger font */
  color: #333;
  font-weight: 500;
  text-align:left;
}

.city-label {
  color: #999;
  font-size: 14px;
  font-style: italic;
}

.city-suggestions li:hover .city-name {
  color: #A2DEF8;
}

.city-suggestions li:hover .city-label {
  color: #666;
}

.popup-image-slider img {
  width: 100%;
  height: 600px;
  object-fit: contain;
}

.listing-image.blurred {
  filter: blur(8px);
  pointer-events: none!important;
}

.listing-content.blurred {
  filter: blur(8px);
  pointer-events: none!important;
}


.popup-content {padding: 3px 0;}

.listing-popup .slick-slide img {height:auto;width:100%;max-width:100%;}

@media (max-width: 768px) {
  .popup-image-slider img {
    width: 100%;
    height:auto;
    object-fit: contain; /* Adjust as necessary for mobile responsiveness */
    transform: scale(0.7); /* Scale down to 70% of the original size */
  }
}

.error-message {display:none;}

/* Existing CSS */

.listing-search-container {
  display: flex;
  flex-direction: column;
  align-items:center!important;
}

.filter-bar {
  display: flex;
  justify-content: space-between;
  padding: 0px 00px;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}

.filter-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;

  padding:15px;
}

.filter-item label {
  font-family: 'Inter';
  font-size: 18px;
  color: #555;
  text-align: left;
  font-weight: 800;
}

.filter-item select,
.filter-item input {
  padding: 10px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
  border-radius:5px;
  border-width:0px;
  background-color:#f3f3f3;
}

.price-range-input {
  width: 100%;
  font-size: 16px;
}

.applied-filters-bar {
  display: flex;
  gap: 10px;
  padding: 10px;
  background-color: #F3F3F3;
  max-width:1500px;
  width:100%;
  padding-left:0px!important;
  padding-right:0px!important;

}

.filter-button,
.clear-filters-button {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.remove-filter {
  color: #f00;
  cursor: pointer;
}

.main-content {
  display: flex;
  gap: 20px;
  padding-bottom:20px;
}

.map-column {
  width: 30%;
  height: 80vh;
}

.listings-column {
  width: 100%;
}

.listing-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding:10px;
}


.listing-card {
  width: calc(25% - 20px); /* 4 columns */
  padding: 5px;
  border-radius: 5px;
  position: relative;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  min-height:400px;
}



.listing-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
  padding-bottom: 10px;
}

.image-container img {
  border-radius: 0px !important;
}

.listing-card img:hover {
  transform: scale(1.02);
}

/* Blurred content for sold listings */
.listing-card.sold .listing-content {
  filter: blur(5px);
  pointer-events: none;
}

/* Hide blur effect if user is logged in */
.listing-card.sold.user-logged-in .listing-content {
  filter: none;
  pointer-events: all;
}

/* Login button overlay for non-logged-in users */
.login-button-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: black;
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  z-index: 10;
  pointer-events: none;
}

.login-button-overlay a.login-button {
  color: black;
  text-decoration: none;
  font-weight: bold;
}

/* Custom scrollbar for the popup */
.listing-popup {
  overflow-y: scroll;
}

.listing-popup::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

.listing-popup::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the scrollbar track */
}

.listing-popup::-webkit-scrollbar-thumb {
  background-color: #9CDCF8; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the scrollbar thumb */
}

.listing-popup::-webkit-scrollbar-thumb:hover {
  background-color: #7bb7e0; /* Color when hovering over the scrollbar */
}

.listing-style-badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #9CDCF8;
  color: black;
  font-family: 'Inter';
  padding: 8px 15px;
  border-radius: 0px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px; /* Adjust as needed */
}

.login-button {
  color: black;
  text-decoration: none;
  font-weight: bold;
  background-color: #9CDCF8;
  padding: 10px 20px;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  display:block;
  pointer-events:all;
}

.login-button:hover {
  background-color: #9CDCF8;
}

.listing-slider {
  position: relative;
  z-index: 0;
}

.slider-item {
  border-radius: 5px;
}

.listing-title {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.title-community {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.community, .street-address {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: #555;
  margin: 0;
}

.street-address {
  font-size: 18px;
  font-weight: bold;
}

.listing-details {
  display: flex;
  justify-content: left;
  margin: 0px 0px 10px 0px;
  gap: 6px;
  font-family: 'Inter', serif;
  font-size: 16px;
}

.listing-detail-item {
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius:0px;
}

.icon {
  width: 24px; /* Adjust the size as needed */
  height: 24px; /* Adjust the size as needed */
}

.listing-price {
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  color: #000;
  text-align: right;
}

.separator-dot {
  margin: 0 5px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination-button {
  margin: 0 5px;
  padding: 10px 15px;
  border: none;
  background-color: #f0f0f0;
  color: #333;
  cursor: pointer;
}

.pagination-button.active {
  background-color: #9cdcf8;
  color: black!important;
}

.pagination-button:hover {
  color: #fff;
}

a:-webkit-any-link {
  color: inherit;
  text-decoration: none;
}

.listing-card a {
  color: inherit;
  text-decoration: none;
  font-family:'Inter';
}

.listing-card a:hover {
  text-decoration: none;
}

.slick-next:hover, .slick-prev:hover {
  background-color: transparent;
}

@media (max-width: 1300px) {
  .listing-card {
    width: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
  }

  .map-column {
    display: none;
  }

  .listings-column {
    width: 100%;
  }

  .listing-card {
    width: calc(50% - 20px);
  }

  .filter-bar {
    flex-direction: column;
    gap: 10px;
  }

  .filter-item {
    margin-right: 0;
  }

  .filter-item.price-range-filter {
    width: 100%;
    padding-right:20px;
  }

  .filter-item:not(.price-range-filter) {
    flex: 1;
  }

  .filter-bar .filter-item:nth-child(2),
  .filter-bar .filter-item:nth-child(3) {
    flex: 1;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  .filter-bar .filter-item:nth-child(4),
  .filter-bar .filter-item:nth-child(5) {
    flex: 1;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  .filter-bar .filter-item:nth-child(2) select,
  .filter-bar .filter-item:nth-child(3) select,
  .filter-bar .filter-item:nth-child(4) select,
  .filter-bar .filter-item:nth-child(5) input {
    width: 100%;
  }

  .listing-popup .listing-slider-container {
    max-height: 250px;
    overflow: hidden;
  }

  .listing-details {
    flex-wrap: wrap;
  }
}

@media (max-width: 480px) {
  .listing-card {
    width: 100%;
  }
}

/* Styles for the popup */
.listing-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 0px;
  z-index: 1000;
  max-width: 1800px;
  width:100%;
  max-height: 80%;
  overflow-y: auto;
}



.listing-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Additional styles for search input and icon */
.search-filter {
  position: relative;
}

.search-input {
  padding-left: 30px;
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  font-size: 18px;
  color: #888;
}

.sold-listing-link {
  display: block;
  position: relative;
  text-decoration: none;
  color: inherit;
}

.sold-listing-link::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  z-index: 1;
}

.sold-listing-link .login-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: black;
}

/* Hide blur effect if user is logged in */
.listing-card.sold.user-logged-in::before {
  background: none;
  backdrop-filter: none;
  display: none;
}

.listing-card.sold.user-logged-in .login-button {
  display: none;
}

/* Add this to the existing styles in Listings.css */
.wishlist-icon-container {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}

.wishlist-icon {
  font-size: 1.5rem;
  color: #000; /* Black outline for non-active heart */
  cursor: pointer;
  transition: color 0.3s ease;
}

.wishlist-icon.active {
  color: #9CDCF8; /* Filled blue heart for active state */
}

.wishlist-icon:hover {
  color: #9CDCF8; /* Change to desired hover color */
}

/* Add pop animation */
@keyframes pop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.pop-animation {
  animation: pop 0.3s ease;
}

/* Ensure the arrows and dots are visible and clickable */
.slick-arrow,
.slick-dots {
  z-index: 2; /* Ensure they are above other elements */
}

/* Ensure the popup overlay has a higher z-index */
.listing-popup-overlay {
  z-index: 999;
}

/* Ensure the popup has a higher z-index than the arrows */
.listing-popup {
  z-index: 1000;
}

/* Additional styling to ensure the arrows are visible when the user is logged in */
.listing-card.sold.user-logged-in .slick-arrow,
.listing-card.sold.user-logged-in .slick-dots {
  display: block !important;
}

/* Placeholder for the map */
.map-placeholder {
  width: 100%;
  height: 100%;
  background-color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #555;
  font-family: 'Inter', sans-serif;
}

.listings-count {
  text-align: center;
  margin: 10px 0;
  font-size: 14px;
  color: #555;
  font-family: 'Inter', sans-serif;
}

.listing-card.sold .listing-content {
  filter: blur(5px);
  pointer-events: none;
}

.listing-card.sold.user-logged-in .listing-content {
  filter: none;
  pointer-events: all;
}

/* Apply blur to the entire card content */
.listing-card.blurred .blur-effect {
  filter: blur(5px)!important;
  pointer-events: none!important;
}

.listing-card.blurred {
  pointer-events:none!important;
}

.login-button-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: black;
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  z-index: 10;
  pointer-events: all;
}

.login-button-overlay a.login-button {
  color: black;
  text-decoration: none;
  font-weight: bold;
}

.listing-search-container .listings-column .slick-dots ul {padding:0px;}

.listing-search-container .street-address {font-family:'Inter';color:black;font-weight:200;font-size:18px!important;}

.listing-search-container .community {color:#5f5e5e;font-family:'Inter';}

.listing-search-container .listing-detail-item {font-family:Inter;background-color:#f7f7f7; border-radius:0px; padding:5px; border:0px solid rgb(202, 202, 202);}

.listing-search-container .listing-title {padding:0px 10px;}

.listing-search-container .listing-price {font-weight:200; font-size:22px;}
.listing-search-container .icon {width:30px;}

.listing-search-container .input-range__label {color:black;font-family:'Inter';font-size:14px;}

.listing-search-container .filter-item label {text-decoration:underline;margin-bottom:5px;color:black;font-family:Inter;font-weight:200;}

.price-range-filter .input-range {width:80%;margin-left:10px;}

.listings-column .listing-title {
  display: flex;
  justify-content: space-between;
  padding: 0px 0px!important;
  flex-wrap: nowrap; /* Prevent wrapping */
}

.listings-column .title-community {
  flex-grow: 1; /* Allow the address/community to take up available space */
  margin-right: 20px; /* Add some spacing between the community and price */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis if the text is too long */
}

.listings-column .listing-price {
  margin-top:10px!important;
  flex-shrink: 0; /* Prevent the price from shrinking */
  font-weight: 600; /* Make the price stand out */
  font-size: 20px; /* Adjust the font size as needed */
  text-align: left;
  white-space: nowrap; /* Prevent price from wrapping */
  font-family:'Inter';
}

.listings-column .custom-dot {box-shadow:0px 0px 30px #00000021;border:1px solid black;}

.listing-style-badge a {font-family:'Inter';}

.listing-popup .custom-listing-container {
  display:none;
}

@media (max-width:700px) {
  .popup-content {
    padding:10px 5px!important;
  }}

@media (max-width:1024px) {
  .popup-content {
    padding:10px 5px!important;
  }

  .listing-card {
    min-height:350px!important;
  }
}

.price-inputs {
  display: flex;
  gap: 10px;
  align-items: center;
}

.price-input {
  width: 100px;
  padding: 10px;
  font-size: 14px;
  box-sizing: border-box;
  border: 1px solid #777777;
  border-radius: 2px;
}

.price-inputs span {
  font-size: 18px;
  font-family: 'Inter';
  color: #555;
}

.listing-image {
  transition: filter 0.3s ease-in-out;
}

.listing-image.blurred {
  filter: blur(8px);
}



.popup-content .listing-info a {background-color:#9CDCF8; border-radius:5px;padding:8px 15px; font-family:'Inter';border:1px solid black;}

/* Close button styles */
.popup-close-button {
  position: absolute;
  top: 15px;
  right: 10px;
  color: black;
  border: none;
  border-radius: 3px;
  width: 30px;
  height: 30px;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  line-height: 30px;
  font-size:18px;
  font-weight:400;
  margin-top:10px;
  background-color:white!important;
}

/* Popup content columns */
.popup-content {
  display: block;
  gap: 20px;
  padding: 3px 0;
  overflow-x:hidden;
}

.popup-content img {max-width:800px;}

.popup-left-column,
.popup-right-column {
  flex: 1;
}

.popup-left-column .listing-info {
  margin-top: 20px;
}

.popup-right-column {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.popup-right-column h4 {
  margin-bottom: 15px;
  font-family: 'Inter';
}

.popup-right-column p {
  margin: 10px 0;
  font-family: 'Inter';
  font-size: 16px;
  color: #333;
}

.popup-content .slick-list {max-width:850px;width:100%;}

.popup-content .slick-dots ul {padding:0px;}

.popup-content .listing-info p {font-family:'Inter';}

.transition-container {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.transition-container.loading {
  opacity: 0;
}

.popup-content .slick-slide img {
  max-height:700px!important;
}

/* Keyframes for slanted grey flash animation */
@keyframes slanted-flash {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

/* Loading card styles */
.listing-card.loading-card {
  background: linear-gradient(120deg, #e0e0e0 25%, #f8f8f8 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: slanted-flash 1.5s infinite ease-in-out;
  border-radius: 5px;
  height: 200px; /* Adjust the height as needed */
  position: relative;
  overflow: hidden;
}

/* Ensure that the loading-card retains the dimensions and spacing of a normal card */
.listing-card.loading-card::before {
  content: "";
  display: block;
  padding-bottom: 100%; /* Adjust based on the aspect ratio */
}

/* Wrapper for the sticky behavior */
.share-popup-wrapper {
  position: relative; /* Wrapper around the share popup */
  z-index: 1001; /* Ensure it's above other elements */
}

/* Share Popup Styles */
.share-popup {
  position: sticky; /* Keeps the share popup in view when scrolling */
  top: 100px; /* Adjust this value to move the popup down the screen */
  margin: 0 auto; /* Center the popup horizontally */
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 400px;
  width: 90%; /* Responsive width */
  z-index: 1001; /* Ensure it's above other elements */
}

.banner-left {padding:10px;border-radius:0px;border-width:1px!important;background-color:#F3FAFD;border:solid;}

.banner-icon {font-size:18px!important;padding-left:5px;padding-top:0px;color:#333;}

.share-link {font-size:17px;font-family:'Inter';display:flex;}

.share-popup-content {
  text-align: center;
}

.share-popup-content h4 {
  margin-bottom: 20px;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  color: #333;
}

.share-popup-content input.share-link-input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #f9f9f9;
}

.share-popup-content input.share-link-input:focus {
  outline: none;
  border-color: #9cdcf8;
}

.share-popup-content .copy-link-button {
  margin-top: 15px;
  background-color: #9cdcf8;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.share-popup-content .copy-link-button:hover {
  background-color: #7bb7e0;
}

.share-popup-content .copy-link-button:active {
  transform: scale(0.98);
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;
  color: #555;
  cursor: pointer;
}

.close-icon:hover {
  color: #000;
}

/* Overlay to dim the background */
.share-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

@media (max-width:1024px) {
  .listing-history-table td {
    font-size:15px;
  }

  .listing-history-table th {
    font-size:17px;
  }

  .listing-popup {
    width:95%;
    overflow-x:hidden;
  }
}

@media (max-width:768px) {
  #root > div > div.listing-search-container > div.filter-bar > div:nth-child(4) {
    display:none!important;
  }

  #root > div > div.listing-search-container > div.filter-bar > div:nth-child(3)
  {
    display:none!important;
  }

  #root > div > div.listing-search-container > div.filter-bar > div:nth-child(2) {
    display:none!important;
  }

  #root > div > div.listing-search-container > div.filter-bar > div:nth-child(1)
  {
    display:none!important;
  }
}

/* Hide the mobile filter section on larger screens */
.mobile-filter-section {
  display: none;
  padding: 10px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
}

.filter-toggle-btn {
  width: 100%;
  background-color: #FDFDFD;
  color: black;
  padding: 10px;
  border: none;
  text-align: left;
  font-size: 1.2rem;
  cursor: pointer;
}

.mobile-filter-options {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 10px;
}

.mobile-filter-options .filter-item {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1070px) {
  /* Show the mobile filter section on smaller screens */
  .mobile-filter-section {
    display: block;
    background-color: #FDFDFD;
    margin-bottom:0px;
    width:100%;
    border-radius:5px;
  }

  .main-content {margin-top:15px!important;}

  /* Hide the desktop filter bar on mobile */
  .filter-bar {
    display: none;
  }

  .applied-filters-bar {display:none!important;}
}

.search-input-container {
  position: relative;
  width: 100%; /* Ensure the container takes full width */
}

.search-input {
  width: 100%;
  padding-right: 40px; /* Make space for the search icon */
  box-sizing: border-box; /* Ensure padding is accounted for in width */
}

.search-button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #888;
}

.search-button:hover {
  color: #000; /* Change color on hover */
}

.city-suggestions {
  border: 1px solid #ccc;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  position: absolute;
  width: 100%;
  list-style-type: none;
  margin: 5px 0;
  padding: 0;
  z-index: 10;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.city-suggestions li {
  padding: 15px;  /* Increased padding for larger height */
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-bottom: 1px solid #eee;
}

.city-suggestions li:last-child {
  border-bottom: none;
}

.city-suggestions li:hover {
  background-color: #fafafa;
}

.suggestion-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.city-name {
  font-size: 15px;  /* Slightly larger font */
  color: #333;
  font-weight: 500;
  text-align:left;
}

.city-label {
  color: #999;
  font-size: 14px;
  font-style: italic;
}

.city-suggestions li:hover .city-name {
  color: #A2DEF8;
}

.city-suggestions li:hover .city-label {
  color: #666;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px; /* Adjust height as needed */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@media (max-width:768px) {
  .listing-search-container .community {font-size:11px!important;}
}

/* Property Type Filter Styles */
.property-type-filter {
  position: relative;
}

.property-type-filter .dropdown-container {
  width: 100%;
}

.property-type-filter .dropdown-toggle {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  box-sizing: border-box;
  border-radius: 5px;
  border-width: 0px;
  background-color: #f3f3f3;
  text-align: left;
  cursor: pointer;
  font-family: 'Inter';
  color:black!important;
}

.property-type-filter .dropdown-menu {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #eee;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  z-index: 1000;
  padding: 10px;
}

.property-type-filter .checkbox-label {
  display: flex;
  align-items: center;
  padding: 8px 0;
  font-family: 'Inter';
  font-size: 14px;
  color: #333;
  cursor: pointer;
}

.property-type-filter .checkbox-label input {
  margin-right: 10px;
}

.property-type-filter .apply-button {
  width: 100%;
  padding: 8px;
  margin-top: 10px;
  background-color: #9CDCF8;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Inter';
  font-size: 14px;
}

.property-type-filter .apply-button:hover {
  background-color: #7bb7e0;
}

/* Ensure the dropdown is shown above other content */
.property-type-filter .dropdown-menu {
  z-index: 1001;
}

/* Make sure the container doesn't clip the dropdown */
.filter-item.property-type-filter {
  overflow: visible;
}

/* Style for the dropdown arrow */
.property-type-filter .dropdown-toggle span {
  float: right;
  font-size: 12px;
  line-height: 20px;
  color: #666;
}

#root > div > div > div.filter-bar > div.filter-item.property-type-filter > div > div > label:nth-child(1) > input[type=checkbox] {width:10%;text-decoration: none!important;}

#root > div > div > div.filter-bar > div.filter-item.property-type-filter > div > div > label:nth-child(2) > input[type=checkbox] {width:10%;}

#root > div > div > div.mobile-filter-section > div > div.filter-item.property-type-filter > div > div > label:nth-child(1) > input[type=checkbox] {width:10%;}

#root > div > div > div.mobile-filter-section > div > div.filter-item.property-type-filter > div > div > label:nth-child(2) > input[type=checkbox] {width:10%;}

#root > div > div > div.filter-bar > div.filter-item.property-type-filter > div > div > label:nth-child(3) > input[type=checkbox] {width:10%;}

#root > div > div > div.filter-bar > div.filter-item.property-type-filter > div > div > label:nth-child(1) {text-decoration:none;color:black!important;}

#root > div > div > div.filter-bar > div.filter-item.property-type-filter > div > div > label:nth-child(2) {text-decoration:none;color:black!important;}

#root > div > div > div.filter-bar > div.filter-item.property-type-filter > div > div > label:nth-child(3) {text-decoration:none;color:black!important;}

#root > div > div > div.mobile-filter-section > div > div.filter-item.property-type-filter > div > div > label:nth-child(3) > input[type=checkbox] {width:10%;}

#root > div > div > div.mobile-filter-section > div > div.filter-item.property-type-filter > div > div > label:nth-child(1) {text-decoration:none;color:black!important;}

#root > div > div > div.mobile-filter-section > div > div.filter-item.property-type-filter > div > div > label:nth-child(2) {text-decoration:none;color:black!important;}

#root > div > div > div.mobile-filter-section > div > div.filter-item.property-type-filter > div > div > label:nth-child(3) {text-decoration:none;color:black!important;}