.unique-services-table {
    margin: 60px;
    margin-bottom:15px;
    font-size: 24px;
    line-height:42px;
    border-radius: 5px;
    overflow: hidden;
    font-family:'Inter';
    max-width:1500px;
    margin-left:auto;
    margin-right:auto;
  }
  
  @media(max-width: 740px) {
    .unique-services-table table { font-size: 18px; line-height:24px; }
    .fa-check, .fa-times { font-size: 22px!important; }
  }
  
  .unique-services-table table img { max-width: 12vw !important; }
  
  .unique-services-table table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-bottom: 0px;

    background-color: white;
    border: 2px solid white;
  }
  
  @media (max-width:1550px) { .unique-services-table table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-bottom: 0px;
    background-color: white;
    border: 2px solid white;
}}

  .unique-services-table th, .unique-services-table td {
    padding: 10px 0px;
    text-align: center;
    border: 1px solid #FFF !important;
    position: relative;

  }
  
  .unique-services-table th:first-child, .unique-services-table td:first-child {
    background-color: hsla(0, 0%, 50.2%, .0705882353);
    font-weight: bold;
  }
  
  .unique-services-table th:nth-child(2), .unique-services-table td:nth-child(2) {
    background-color: #8ED9F8;
  }
  
  .unique-services-table th:last-child, .unique-services-table td:last-child {
    background-color: #252525;
    color: #fff !important;
    font-weight: bold;
  }
  
  .fa-check, .fa-times {
    font-size: 32px;
    color: #000;
  }
  
  .fa-times {
    color: #bb8888;
  }
  
  /* Specific CSS for the third column checkmarks */
  .unique-services-table td:nth-child(3) .fa-check {
    color: #fff;
  }
  
@media(max-width:991px) {

.unique-services-table {margin:2rem;}

}
