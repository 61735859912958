/* src/Auth.css */

/* Styles for login and register pages */
.login-page-body,
.register-page-body {
  background-image: url('./assets/images/login.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100%;
  margin: 0;
  padding: 0;
}

.login-page,
.register-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-container,
.register-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 40px;
  background-color: white;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-container h1,
.register-container h1 {
  text-align: center;
  margin-bottom: 20px;
}

.login-container form,
.register-container form {
  display: flex;
  flex-direction: column;
}

.login-container input,
.register-container input {
  margin: 10px 0;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}

.login-container button,
.register-container button {
  margin: 20px 0 10px;
  padding: 12px;
  background-color: #9cdcf8;
  color: black;
  border: none;

  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.login-container button:hover,
.register-container button:hover {
  background-color: #9cdcf8;
}

.error {
  color: #dc3545;
  margin-bottom: 10px;
  text-align: center;
}

.success {
  color: #28a745;
  margin-bottom: 10px;
  text-align: center;
}

.login-container p,
.register-container p {
  text-align: center;
  margin-top: 20px;
}

.login-container a,
.register-container a {
  color: #9cdcf8;
  text-decoration: none;
}

.login-container a:hover,
.register-container a:hover {
  text-decoration: underline;
}