.share-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.share-popup-content {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  max-width: 400px;
  width: 90%;
  position: relative;
}

.share-popup-content h2 {
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
}

.close-button-x {
  position: absolute;
  top: 10px;
  left: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
}

.share-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.share-option-button {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border: none;
  background-color: #f0f0f0;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
}

.share-option-button:hover {
  background-color: #e0e0e0;
}

.share-option-button svg {
  font-size: 20px;
}

@media (max-width: 768px) {
  .share-popup-content {
    width: 95%;
  }
}