/* Global Styles */
html,
body {
  height: 100%;
  margin: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

body {
  box-sizing: border-box;
  overflow-y: auto; /* Allow vertical scrolling on the body */
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

/* Commission Explained Page Container */
.commission-explained-container {
  display: flex;
  flex-direction: column;
  min-height: 50vh; /* Ensure it takes the full viewport height */
  
  
  justify-content: center;
  align-items: center;
}

.boxes-section h3 {
  font-weight: bold;
}

.hero-section-explained {width:100%;justify-content:center;background-color:#F3F3F3;align-items: center;align-content: center;display:flex;}

.hero-section-commission .divider {
  width: 50px;
  height: 2px;
  background-color: grey;
  margin: 0 auto 1rem;
}

.hero-section-commission p {
  margin-bottom: 2.5rem; /* Add margin below the heading */
}

.hero-section-commission::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.commission-explained-container .content-image-section {
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  padding:0rem 1rem;
}

.content-section-inner-content {max-width:none;margin:0;display:flex;justify-content:center;}


.commission-explained-container .content-section {
  max-width:none;
  background-color:white;
  
  
  width:100%;
  justify-content:center;
  align-items: center;
}

.text-section {max-width:1500px;}

.text-section-bottom {justify-content: center;display:flex;padding-bottom:30px;}

.text-section-bottom p {margin-left:15px;margin-right:15px;}

@media (min-width: 769px) { 
  .commission-explained-container .content-image-section p {
  text-align: center;
  font-family:'Inter';
}}

/* Content Section */
.commission-explained-container .content-section p {
  font-family: 'Inter';
  font-size: 1.5rem;
  line-height: 3.25rem;
}

@media (max-width: 768px) {
  .commission-explained-container .content-section p {
    font-family: 'Inter';
    font-size: 1rem;
    line-height: 2.5rem;
    text-align: center;
  }
}

.content-section h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-family: 'Inter';
  margin-top:40px;
  margin-bottom:40px;
}

.commission-explained-container .featured-listings-heading {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-family: 'Inter';
  margin-top:0px!important;
  margin-bottom:0px!important;
  width:100%;
}

.content-section .divider {
  width: 125px;
  height: 2px;
  background-color: grey;
  margin: 3.5rem auto 3.5rem;
}

.content-section p {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.spacing {
  margin-bottom: 7.5rem; /* Added for spacing */
}

/* Image and Text Section */
.commission-image-text-section {
  display: flex;
  align-items: center;
  margin: 2rem 0;
  padding-right: 1rem; /* Add 1rem padding to the right columns */
}

@media (max-width: 991px) {
  .commission-image-text-section {
    display: flex;
    align-items: center;
    margin: 2rem 0;
    padding-right: 1rem;
    flex-direction: column; /* Add 1rem padding to the right columns */
  }
}

@media (max-width: 991px) {
  .commission-image-text-section .divider {
    display: none;
  }
}

.commission-section-image {
  position: relative;
  max-width: 50%;
  margin-right: 2rem;
  overflow: hidden; /* Ensure the pseudo-element does not overflow */
}

.commission-section-image::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background-color: white; /* Change this to match your background color */
  clip-path: polygon(0 0, 100% 50%, 0 100%);
}

/* Add this rule to create a small chevron effect on the right edge of images */
.commission-image-text-section .commission-section-image {
  position: relative;
  clip-path: polygon(
    0 0,
    calc(100% - 10px) 0,
    calc(100% - 10px) calc(50% - 20px),
    calc(100% + 1px) 50%,
    calc(100% - 10px) calc(50% + 20px),
    calc(100% - 10px) 100%,
    0 100%
  );
}

.commission-section-text {
  flex: 1;
  font-size: 1.5rem;
  line-height: 1.75rem;
  text-align: left;
  padding: 2.5rem;
}

.commission-section-text .divider {
  width: 125px;
  height: 2px;
  background-color: grey;
  margin: 1.5rem 0; /* Adjust margin as needed */
  text-align: left;
}

.commission-highlight {
  color: #9cdcf8; /* Highlight color */
  text-shadow: 1px 1px 0px #577999;
}

/* Boxes Section */
.boxes-section {
  display: flex;
  justify-content: space-around;
  margin: 0 0;
  flex-wrap: wrap; /* Allow boxes to wrap in smaller screens */
}

.box {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  flex: 1;
  margin: 15px;
}

.commission-explained-container h3 {
  font-size: 2rem;
  color:#1b478c;
}


.box img {
  width: 100%;
  max-width: 150px;
  margin-bottom: 1rem;
  border-radius: 15px;
}

.commission-explained-container .content-image-section img {

border-radius:5px;


}

.box h3 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  font-family: 'Inter';
}

@media (max-width:768px) {.box h3 {
  font-size: 2rem;
  margin-bottom: 2rem;
  font-family: 'Inter';
}}

.commission-image-text-section h3 {
  line-height: 3rem;
  font-family: 'Inter';
}

.content-section .content-image-section {
  max-width: 1500px;
  background-color: #F3F3F3;
  border-radius: 5px !important;
  padding: 30px;
}

@media (max-width: 1520px) {
  .content-section .content-image-section {
      max-width: 1500px;
      background-color: #F3F3F3;
      border-radius: 5px !important;
      padding: 30px;
      margin-left: 15px;
      margin-right: 15px;
  }}

@media (max-width: 991px) {
  .commission-image-text-section h3 {
    line-height: 3rem;
    text-align: center;
  }
}

@media (max-width: 991px) {
  .commission-image-text-section p {
    font-family: 'Inter';
    text-align: center;
    font-size: 1.75rem;
    line-height: 3.25rem;
  }
}

.box p {
  font-size: 1.2rem;
  margin: 0.5rem 0;
}

/* Button Styles */
.commission-explained-container button {
  font-size: 1.5rem;
  max-width: 450px;
  background-color: #9cdcf8; /* Light blue background color */
  color: black; /* Black text color */
  font-family: 'Inter';
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  display: inline-block; /* Ensure buttons are not full width */
  font-weight: 200;
  padding: 1rem 2rem;
  border-width: 0px;
}

.commission-explained-container button:hover {
  background-color: #7bb7e0; /* Slightly darker blue on hover */
}

.hero-section-commission {
  position: relative;
  background-size: cover;
  background-position: center center;
  color: white;
  padding: 5.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden; /* Ensure overlay is contained */
  border-radius:5px;
  margin-bottom:15px;
  max-width:1500px;
  width:100%;
}

@media (max-width: 1500px) {
  .hero-section-commission {
    max-width:98%!important;
  }
}

@media (max-width: 1100px) {
  .hero-section-commission {
    max-width:97%!important;
  }
}

@media (max-width: 768px) {
  .hero-section-commission {
    max-width:94%!important;
  }
}


@media (max-width: 991px) {
  .hero-section-commission {
    position: relative;
    background-size: cover;
    background-position: top center;
    color: white;
    padding: 5.5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden; /* Ensure overlay is contained */
    padding: 2rem;
  }
}

@media (max-width: 991px) {
  .hero-section-commission .divider {
    display: none;
  }
}

@media (max-width: 991px) {
  .hero-section-commission p {
    margin-top: 0px;
  }
}

.hero-section-commission h1 {
  font-size: 3.5rem;
  line-height: 4rem;
  font-family: 'Inter';
  font-weight: 200;
  margin-bottom: 1rem;
  z-index: 2;
}

@media (max-width:768px) {.hero-section-commission h1 {
  font-size: 2.5rem;
  line-height: 4rem;
  font-family: 'Inter';
  font-weight: 200;
  margin-bottom: 1rem;
  z-index: 2;
}
.hero-section-commission p {
  font-size: 2rem;
  margin-bottom: 1rem;
  line-height: 3.5rem;
  color: white;
  font-family: 'Inter';
  z-index: 2;
}
}



.hero-section-commission p {
  font-size: 2.25rem;
  margin-bottom: 1rem;
  line-height: 3.5rem;
  color: white;
  font-family: 'Inter';
  z-index: 2;
}

@media (max-width:768px) { .hero-section-commission p {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  line-height: 3.5rem;
  color: white;
  font-family: 'Inter';
  z-index: 2;
}
}

.hero-section-commission .read-more-button-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  z-index: 99;
}

.slider-wrapper {
  max-width: 100%;
}

.talk-button {
  margin-top: 1rem;
}

.valuation-button {
  margin-top: 1rem;
}

/* Pricing Table */
.pricing-table {
  width: 100%; /* Ensure the table container takes full width */
  overflow-x: auto; /* Allow horizontal scrolling if needed */
  margin: 2rem 0; /* Add some spacing around the table */
  font-family: 'Inter'; 
  border-radius: 20px;
}

.pricing-table table {
  width: 100%; /* Ensure the table itself takes full width */
  border-collapse: collapse; /* Ensure borders collapse into a single border */
  font-size: 1.5rem; /* Increase font size */
}

.pricing-table th,
.pricing-table td {
  border: 3px solid white; /* Add 2px white borders to table cells */
  padding: 16px; /* Add padding to table cells */
  text-align: center; /* Center align text in table cells */
}

.pricing-table th {
  background-color: #fff; /* Add background color to header cells */
  font-weight: bold; /* Make header text bold */
}

.pricing-table tbody tr:nth-child(even) td:nth-child(2) {
  background-color: #e9cccc; /* Light shade for column 2 */
}

.pricing-table tbody tr:nth-child(odd) td:nth-child(2) {
  background-color: #e2b3b3; /* Darker shade for column 2 */
}

.pricing-table tbody tr:nth-child(even) td:nth-child(3) {
  background-color: #d3d3d3; /* Light shade for column 3 */
}

.pricing-table tbody tr:nth-child(odd) td:nth-child(3) {
  background-color: #bfbfbf; /* Darker shade for column 3 */
}

.pricing-table tbody tr:nth-child(even) td:nth-child(4) {
  background-color: #66c4db; /* Light shade for column 4 */
  font-weight: bold; /* Bold text for Modern 1% + 2% column */
}

.pricing-table tbody tr:nth-child(odd) td:nth-child(4) {
  background-color: #4daecc; /* Darker shade for column 4 */
  font-weight: bold; /* Bold text for Modern 1% + 2% column */
}

.pricing-table th:nth-child(4),
.pricing-table td:nth-child(4) {
  font-weight: bold; /* Ensure all text in Modern 1% + 2% column is bold */
}

/* Ensure text in specific section is left-aligned */
.left-align-text p {
  text-align: left;
}

/* Full Width Section */
.full-width-section {
  width: 100%;
  background-color: #f0f0f0;
  padding: 2rem 0; /* Remove horizontal padding */
  min-height: 600px;
}

.full-width-section h2 {
  text-align: center;
  margin-bottom: 2rem;
  font-family:'Inter';
}

.charts-container {
  display: flex;
  flex-wrap: wrap; /* Allow charts to wrap on smaller screens */
}

#map-container {
  position: relative;
  width: 100%;
  max-width: 850px; /* Limit max width */
  height: 450px;
  /* Add spacing between charts */
  margin: 20px;
}

#overlay {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 850px; /* Limit max width */
  height: 450px;
  background-color: rgba(142, 217, 248, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.2rem;
}

#homePriceChart {
  width: 100%;
  max-width: 850px; /* Limit max width */
  height: 450px;
  margin-bottom: 2rem; /* Add spacing between charts */
  margin: 20px;
}

/* New Content Section */
.new-content-section {
  margin-bottom: 3rem;
}

.new-content-section h3 {
  font-size: 2rem;
  text-align: center;
}

.new-content-section p {
  font-size: 1.5rem;
  text-align: center;
  margin: 0.5rem 0;
}

/* Media Queries */
@media (max-width: 768px) {
  .commission-explained-container .content-section {
    padding: 0rem;
  }

  .content-section h2 {
    font-size: 2rem;
  }

  .content-section p {
    font-size: 1rem;
    line-height: 1.75rem;
  }

  .commission-image-text-section {
    flex-direction: column;
  }

  .commission-section-image {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .commission-section-text {
    padding: 1rem;
    text-align: center;
  }

  .boxes-section {
    flex-direction: column;
  }

  .box {
    margin: 1rem 0;
  }

  .pricing-table th,
  .pricing-table td {
    padding: 1px;
  }

  .pricing-table {
    font-size: 1.2rem;
  }

  .pricing-table table {
    font-size: 0.8rem;
  }

  .full-width-section h2 {
    font-size: 1rem;
  }

  #map-container,
  #homePriceChart {
    height: 300px;
  }
}

.msr-logo {
  width: 100%;
  max-width: 150px;
  height: auto;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .msr-logo {
    max-width: 200px;
  }
}

@media (min-width: 1024px) {
  .msr-logo {
    max-width: 250px;
  }
}

/* Alternating Image and Text Section */
.commission-content-image-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5rem;
  gap: 3rem; /* Add gap between text and image */
}

.commission-text-content {
  flex: 1;
  text-align: left;
  padding: 2rem;
}

.commission-image-content {
  flex: 1;
}

.commission-image-placeholder {
  width: 100%;
  max-width: 600px;
  height: auto;
  box-shadow: 10px 10px 0px #1b478c; /* Add box shadow to the image */
}

.commission-text-content h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #1b478c;
}

.commission-text-content p {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  line-height: 2.5rem;
}

@media (max-width: 768px) {
  .commission-content-image-section {
    flex-direction: column;
    text-align: center;
  }

  .commission-text-content,
  .commission-image-content {
    padding: 0;
    margin-bottom: 1.5rem;
  }
}

/* Alternating Content Alignment */
.commission-content-image-section.text-right .commission-text-content {
  text-align: right;
}

.commission-content-image-section.text-right .commission-image-content {
  justify-content: flex-start; /* Align image to the right */
}

.commission-content-image-section .commission-image-content {
  justify-content: flex-end; /* Align image to the left */
}

/* Animation */
.commission-animate-on-scroll {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.commission-animate-on-scroll.visible {
  opacity: 1;
  transform: translateY(0);
}

.commission-top-container {max-width:1500px;}

.boxes-information {margin:15px;background-color:#F9F9F9;padding:15px;border-radius:5px;}
.commission-explained-container .content-image-section p {
  text-align: left;
  font-family:'Inter';
}

@media (max-width:768px) {
  .boxes-information {margin:15px;background-color:#F9F9F9;padding:15px;border-radius:5px;margin-left:0px;margin-right:0px;}

  .commission-explained-container .content-image-section p {
    text-align: center;
    font-family: 'Inter';
}
.content-image-section.text-right .text-content p {
  text-align: center;
  font-family: 'Inter';
}

.commission-top-container {
  max-width: 1500px;
  padding:15px;
}


}

.commission-explained-container button {padding:15px;text-transform:capitalize;}

.commission-explained-container .slider-button .right {padding:0px;}

