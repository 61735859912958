

.articles-container {
  display: flex;
  justify-content: center;
  
}



.sidebar {
  position: sticky;
  top: 0;
  height: 100vh;
  background-color: #fff;
  box-sizing: border-box;
  border-right: 1px solid #d6d6d6;
  padding: 1rem;
  max-width:500px;
  margin-left:15px;
  margin-right:7.5px;
}

.sidebar h3 {
  font-weight: bold;
}

.article-list {
  list-style: none;
  padding: 0;
  margin-top: 1rem;
}

.sidebar-item {
  cursor: pointer;
  padding: 1rem;
  border-bottom: 1px solid #ddd;
}

.sidebar-item:hover {
  background-color: #9cdcf83d;
}

.content {
  width: 100%;
  padding: 2rem;
  box-sizing: border-box;
  background-color: #f9f9f9;
}

.input-label {
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.input-field {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.editor {
  margin-bottom: 5rem;
  height: 300px!important;
}

.submit-button {
  padding: 0.75rem 2rem;
  background-color: #9cdcf8;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  font-family: 'Inter';
}

.submit-button:hover {
  background-color: #8fcee9;
}

@media (max-width: 768px) {
  .articles-container {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }

  .content {
    width: 100%;
  }
}

.content img {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  margin-top: 10px;
}


@media (max-width:1220px) {
  .articles-container {
    padding-right:15px!important;padding-left:15px!important;display:block;
  }
}
