.top-bar {
  width: 100%;
  background-color: white;
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #d6d6d6;
  box-sizing: border-box;
}

.top-bar-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 1rem;
}

.auth-separator {
  margin: 0 15px;
  color: #d6d6d6;
  font-family: 'Inter', sans-serif;
}

.auth-links {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.auth-link {
  text-decoration: none;
  color: black;
  font-family: 'Inter', sans-serif;
  text-transform: uppercase;
  transition: color 0.5s ease;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.5px;
  white-space: nowrap;
}

.auth-link:hover {
  color: #9cdcf8;
}

.logout-button {
  background: none;
  border: none;
  color: black;
  font-family: 'Inter', sans-serif;
  cursor: pointer;
  transition: color 0.5s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.logout-button:hover {
  color: #9cdcf8;
}

.desktop-auth {
  display: flex;
  align-items: center;
}

/* Mobile styles */
.mobile-auth {
  display: none;
}

@media screen and (max-width: 768px) {
  .mobile-auth {
    display: inline-block;
  }

  .desktop-auth {
    display: none;
  }

  .user-dropdown {
    position: relative;
    display: inline-block;
  }

  .user-icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }

  .dropdown-menu {
    display: block;
    position: absolute;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 999;
    right: 0;
    min-width: 150px;
  }

  .dropdown-item {
    padding: 12px 15px;
    white-space: nowrap;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: black;
    border-bottom: 1px solid #f1f1f1;
  }

  .dropdown-item:last-child {
    border-bottom: none;
  }

  .dropdown-item.logout-button {
    background: none;
    border: none;
    color: black;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
  }

  .dropdown-item:hover {
    background-color: #f1f1f1;
  }
}

/* Add this at the end of your file */
.top-bar.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  padding: 5px 0;
  transition: padding 0.3s ease, font-size 0.3s ease;
  z-index: 1000;
}