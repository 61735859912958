.custom-city-container {
    width: 100%;
    margin: 0 auto;
    max-width: 100%;
    padding-bottom:30px;
    justify-content: center;
    align-content: center;
    align-items: center;
    align-self:center;
}



/* Container Styling */
.widget-container-city {
    background-color: white;
    margin: 30px 60px;
    display: flex;
    justify-content: center;
    position: relative;
    max-width:1500px;
    margin:auto;

  }

  .widget-container-city .slider-button.left {
    left:-60px!important;
  }

  .widget-container-city .slider-button.right 

  {
    right:-60px!important;
  }


  @media (max-width:1600px) {

    .widget-container-city {
        background-color: white;
        padding: 0px 60px;
        display: flex;
        justify-content: center;
        position: relative;
        width:100%;
        margin:0px;
        max-width:100%;
    
      }
    .widget-container-city .slider-button.left {
        left:0px!important;
      }
    
      .widget-container-city .slider-button.right 
    
      {
        right:0px!important;
      }
  }
.custom-city-container h2 {
    font-family: 'Inter';
    font-size: 2.5rem;
    font-weight: 700;
    margin-top:0px;
    padding:20px;
    padding-top:30px;
    
color:#000;

}

.city-slider-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
}

.city-slider {
    display: flex;
    overflow-x: scroll;
    white-space: nowrap;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    margin: 10px 0;
  }
  


  .custom-city-column {
    flex: 0 0 calc(100% / 5 - 20px); /* Adjust to fit five items per view */
    width: calc(100% / 5 - 20px);
    margin: 0 10px;
    border-radius: 20px;
    transition: transform 0.3s;
    background: white;
}

@media (max-width: 1550px) {.custom-city-column {
    flex: 0 0 calc(100% / 4 - 20px); /* Adjust to fit five items per view */
    width: calc(100% / 5 - 20px);
    margin: 0 10px;
    border-radius: 20px;
    transition: transform 0.3s;
    background: white;
}}

@media (max-width: 1210px) {.custom-city-column {
    flex: 0 0 calc(100% / 3 - 20px); /* Adjust to fit five items per view */
    width: calc(100% / 5 - 20px);
    margin: 0 10px;
    border-radius: 20px;
    transition: transform 0.3s;
    background: white;
}}

@media (max-width: 1050px) {
    .custom-city-column {
        flex: 0 0 calc(100% / 3 - 20px); /* Adjust to fit three columns on tablet */
        width: calc(100% / 3 - 20px);
        margin-left:10px!important;
        margin-right:10px!important;
    }

    .widget-container-city {
        background-color: white;
        padding: 30px 40px;
        display: flex;
        justify-content: center;
        position: relative;
        width: 100%;
        margin: 0px;
        max-width: 100%;
    }

    .city-image-container {
        width: 100%;
        height: 250px!important;
        background-size: cover;
        background-position: center;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        position: relative;
        overflow: hidden;
        border-radius: 5px;
    }

}

@media (max-width: 768px) {
    .custom-city-column {
        flex: 0 0 calc(100% / 2 - 20px); /* Adjust to fit two columns on mobile */
        width: calc(100% / 2 - 20px);
        margin: 0 10px;
    }

    .widget-container-city {
        background-color: white;
        padding: 0px;
        display: flex;
        justify-content: center;
        position: relative;
        width: 100%;
        margin: 0px;
        max-width: 100%;
    }

    .city-image-container {
        width: 100%;
        height: 250px;
        background-size: cover;
        background-position: center;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        position: relative;
        overflow: hidden;
        border-radius: 5px;
    }

    .slider-button.left, .slider-button.right {
        width: 56px;
        height: 56px;
        font-size: 1.5rem;
    }

    .slider-button.left {
        left: 10px;
    }

    .slider-button.right {
        right: 10px;
    }
}
  

@media (max-width: 768px) {
    .custom-city-column {
        width: calc(100% - 40px); /* Full width minus margin for gap between listings */
        margin: 0 20px 20px 20px;
    }

    .slider-button.left, .slider-button.right {
        width: 56px;
        height: 56px; /* Ensure the button is a perfect circle */
        font-size: 1.5rem;
    }

    .slider-button.left {
        left: 10px;
    }

    .slider-button.right {
        right: 10px;
    }
}

.city-slider .city-name {
    font-size:25px;
    font-weight:500;
    color:white;

}

@media (max-width:1024px) {
    .city-slider .city-name {
        font-size:18px;
        font-weight:500;
        color:white;
    
    }
}

.city-image-container {
    width: 100%;
    height: 400px;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    overflow: hidden;
    border-radius:5px;
}

.city-image-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #9cdcf829; /* 25% black overlay */
    z-index: 1; /* Ensure the overlay is on top of the image but below the text */
}

.city-name-overlay {
    position: relative;
    z-index: 2; /* Bring the text above the overlay */
    background-color: rgba(0, 0, 0, 0.7); /* Transparent background */
    width: 100%;
    padding: 10px;
    color: white;
}

.city-name {
    font-family: 'Inter';
    font-size: 1.8rem;
}

.svg-inline--fa {height:1.5em;}

.slider-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    border: 0px solid black;
    color: white;
    width: 56px;
    height: 56px;
    cursor: pointer;
    z-index: 10;
    border-radius: 0px; /* Make the button round */
    transition: background-color 0.3s, transform 0.3s;
}

.slider-button.left {
    left: 10px;
}

.slider-button.right {
    right: 10px;
}

.city-slider::-webkit-scrollbar {
    display: none; /* Hide scrollbar */
}

.slider-button {
    position: absolute;
    top: 50%; /* Center the button vertically */
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.7); /* Dark background for visibility */
    color: white;
    width: 56px;
    height: 56px;
    cursor: pointer;
    z-index: 10;
    border-radius: 0; /* Change to 0 if you want square corners */
    font-size: 1.5rem;
    transition: background-color 0.3s, transform 0.3s;
}

.slider-button.left {
    left: 10px;
}

.slider-button.right {
    right: 10px;
}

@media (max-width: 768px) {
    /* Adjust the size and positioning of buttons for smaller screens */
    .slider-button {
        width: 48px;
        height: 48px;
        font-size: 1.3rem;
    }
    .slider-button.left {
        left: 5px;
    }
    .slider-button.right {
        right: 5px;
    }
    
    .custom-city-container h2 {
        font-family: 'Inter';
        font-size: 2rem;
        font-weight: 700;
        margin-top: 0px;
        padding: 20px;
        padding-top: 30px;
        color: #000;
    }
}

@media (max-width:768px) {
    .widget-container-city .slider-button.right {right: -0px !important;}

    .widget-container-city .slider-button.left {left: -0px !important;}
    
}