.thank-you {
    text-align: center;
    padding: 2rem;
    font-family: 'Inter';
	background-color: #e8f1f5;
  max-width:1500px;
  
  border-radius:5px;
  }
  
  .thank-you h1 {
    font-size: 3.5rem;
    margin-bottom: 1rem;
    font-weight:200;
  }

  .thank-you-page {
    background-color:#141414;
    justify-content:center;
    align-items: center;
    display:flex;
  
  }

  .thank-you-page h2 {
    padding-bottom:15px;
  }
  
  .thank-you p {
    font-size: 1.25rem;
  }
  
  .lottie-container {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
  }
  
  .info-boxes {
    display: flex;
    justify-content: space-around;
    margin-top: 2rem;
    flex-wrap: wrap;
}
  
  .info-box {
	background-color:white;
    border: 1px solid #000;
    padding: 1.5rem;
    width: 30%;
    text-align: center;
    font-family: 'Inter';
    text-decoration: none;
    color: inherit;
    transition: background-color 0.3s ease, transform 0.3s ease;
    cursor: pointer;
  }
  
  .info-box:hover {
    background-color: #f9f9f9;
    transform: translateY(-5px);
  }
  
  .info-box h2 {
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  
  .divider {
    width: 5%;
    height: 1px;
    background-color: rgb(200, 200, 200);
    margin: 0.5rem auto;
  }
  
  .info-box p {
    margin-bottom: 1rem;
  }
  
  .info-box button {
    font-size: 1.3rem;
    background-color: #9cdcf8;
    border: 1px solid #000;
    border-radius: 0px;
    color: #000;
    font-family: 'Inter';
    padding: 0.5rem 1.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .info-box button:hover {
    background-color: #7bbcd4;
  }
  
  @media (max-width: 768px) {
    .info-box {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
  
