

.articles-container {
  display: flex;
  padding-left:15px;
  padding-right:15px;
  padding-bottom:15px;
  background-color:#F3F3F3;
}

.sidebar {
  position: sticky;
  top: 0;
  height: 100vh;
  background-color: #fff;
  box-sizing: border-box;
  margin-right:15px;
  border-radius:5px;
}

.articles-container .sidebar h3 {
  font-weight: bold;
}

.sidebar-item {font-family:'Inter';}

.search-bar {
  width: 90%;
  margin: 1rem;
  padding: 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 0px;
  font-size:20px;
  font-family:'Inter';
}

.categories, .recent-articles {
  margin-bottom: 2rem;
}

.category-item, .sidebar-item {
  cursor: pointer;
  padding: 1rem;
  border-bottom: 1px solid #ddd;
  font-family:'Inter';
}

.category-item:hover, .sidebar-item:hover {
  background-color: #9cdcf83d;
}

.article-date {
  font-size: 0.875rem;
  color: #666;
}

.article-category {
  font-size: 1rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.content {
  width: 100%;
  padding: 2rem;
  box-sizing: border-box;
  font-family:'Inter';
}

.content h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.content p {
  line-height: 1.75rem;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .articles-container {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    height: auto;
    position: relative;

  }

  .content {
    width: 100%;
  }
}

/* Divider styling */
.sidebar-divider {
  border: none;
  border-bottom: 1px solid #fff;
  margin: 0 1rem 1rem 1rem; /* Adjust as necessary */
}

/* Left align text */
.articles-container, .sidebar, .content, .category-item, .sidebar-item, .content h2, .content p {
  text-align: left;
}

.articles-container .content {
  background-color: #fff;
  border-radius:5px;
  max-width:985px;
  margin-left:7.5px;
  margin-right:15px;
}

/* Content for search results */
.article-preview {
  border-bottom: 1px solid #dfdfdf; /* Added border below each article */
  padding-bottom: 1rem; /* Added padding for spacing */
  margin-bottom: 1rem; /* Added margin for spacing */
}

.article-preview p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.read-more-button {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 2rem;
  background-color: #9cdcf8;
  color: black;
  border: none;
  border-radius: 0px;
  text-decoration: none;
  cursor: pointer;
  font-size: 19px;
  font-weight: 500;
  font-family: 'Inter';
}

.read-more-button:hover {
  background-color: #8fcee9;
}

.articles-container hr {
  height: 1px;
}

.articles-container .categories h3,
.articles-container .recent-articles h3,
.search-section h3 {
  padding: 0rem 1rem;
  font-family:'Inter';
}

/* View All Articles button */
.view-all-button {
  display: block;
  width: 90%;
  margin: 1rem auto;
  padding: 0.5rem;
  background-color: #9cdcf8;
  color: black;
  border: none;
  text-align: center;
  font-family: Inter, sans-serif;
  cursor: pointer;
  font-size: 19px;
  font-weight: 500;
}

.view-all-button:hover {
  background-color: #7abce0;
}

/* Highlight recent articles in sidebar */
.sidebar-item.highlighted {
  background-color: #9cdcf83d;
}

.articles-container p {font-weight:300;font-size:24px;line-height:36px;font-family:'Inter';}

/* Common styles for sidebar items */
.sidebar-item, .category-item {
  display: block; /* Ensure block-level for padding */
  padding: 10px 15px; /* Adjust padding as needed */
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  text-decoration: none; /* Remove underline from links */
  color: inherit; /* Ensure text color is inherited */
}

.sidebar-item:hover, .category-item:hover {
  background-color: #f0f0f0; /* Optional: Add a hover effect */
}

.selected {
  background-color: #e0e0e0; /* Optional: Different background for selected items */
}

/* Remove additional padding and margin from text elements */
.sidebar-item div, .category-item div {
  margin: 0;
  padding: 0;
}

@media (max-width:1220px) {
  .sidebar {
    max-width:1500px!important;
    margin-left:0px!important;
    margin-bottom:15px;
    margin-right:0px!important;
    position:relative!important;
  }

  .content {
    max-width:1500px!important;
    margin-left:0px!important;
  }

  .articles-container {
    display:row!important;
  }
}

@media (max-width:1100px) {
  .articles-container {padding-right:15px!important;padding-left:15px!important;display:row!important;}
}