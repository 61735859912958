/* Global Styles */
html, body {
  height: 100%;
  margin: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

body {
  box-sizing: border-box;
  overflow-y: auto; /* Allow vertical scrolling on the body */
}

*, *::before, *::after {
  box-sizing: inherit;
}

/* Contact Page Container */
.contact-page-container {
  display: flex;
  flex-direction: column;
   /* Ensure it takes the full viewport height */
  padding: 0rem;
  background-color:white;
  align-items: center;
  justify-content: center;
  padding-bottom:0px;
  

}

.contact-container {background-color:#F3F3F3;width:100%;justify-content: center;align-items: center;margin-left:auto;margin-right:auto;display: flex;}


.contact-form input, .contact-form textarea {border-radius:8px!important;}

.contact-page-container .right-column .divider {
  margin-left: auto;
  margin-right: auto;
}

.contact-form-title {
  margin-left: auto;
  margin-right: auto;
  line-height: 1.4; /* Increase line height */
}

.contact-form-subtitle, .left-column-subtitle {
  line-height: 1.5; /* Adjust line-height proportionally */
}

/* Two-Column Section */
.contact-page-container .two-column-section {
  display: flex;
  flex-direction: row; /* Ensure row direction for desktop */
  margin-bottom: 0rem;
  border-radius:5px!important;
  padding-bottom:15px;
  max-width:1500px;
}

@media (max-width:1550px) {
  .contact-page-container .two-column-section {
    display: flex;
    flex-direction: row; /* Ensure row direction for desktop */
    margin-bottom: 0rem;
    border-radius:5px!important;
    padding-top:0px;
    padding-left:15px;
    padding-right:15px;

    max-width:1500px;
  }
}

.contact-page-container .two-column-section .right-column h3 {
  font-size: 1.75rem;
  line-height: 3rem;
  margin-bottom: 1rem;
  color: #bfbfbf;
  margin-top: 0px;
  font-weight:500;
  
}

.contact-page-container .two-column-section .left-column h3, .new-left-column p {
  font-size: 1.75rem;
  margin-bottom: 1rem;
  text-align: left;
  line-height: 3.25rem;
  margin-top:0px;
}

@media (max-width:1024px) {
/* Two-Column Section */
.contact-page-container .two-column-section  {
  margin:15px;
  border-radius:5px!important;
  padding-right:0px;
  padding-left:0px;
  }

.contact-page-container {padding-top:0px;}

}


.contact-page-container .accordion {
  width: 100%;
}

.contact-page-container .left-column {
  padding: 3rem;
}

.contact-page-container .left-column, .contact-page-container .right-column {
  flex: 1;
  padding: 3rem;
}

.contact-page-container .left-column {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.contact-page-container .right-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-page-container .right-column h2 {
  font-size: 2.5rem;
margin:0vw;  
margin-bottom: 1rem;
	text-align:left;
}

.contact-page-container .divider {
  width: 100px;
  height: 1px;
  background-color: grey;
}

.contact-page-container .divider.left-aligned-divider {
  margin-left: 0;
}

.accordion {
  margin-top: 1rem;
}

.accordion-item {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  border-bottom: 1px solid #ddd;
}

.accordion-header {
  display: flex;
  align-items: center;
  width: 100%; /* Ensure header spans full width */
}

.accordion-icon {
  margin-right: 1rem;
  font-size: 3rem; /* Increase the icon size */
}

.icon-adjustment-large {
  font-size: 2.5rem; /* Increase the icon size */
}

.accordion-title {
  flex-grow: 1;
  text-align: left; /* Left-align the text */
  font-family: 'Inter';
  font-size: 24px;
}

.accordion-arrow {
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 20px;
  color: #000; /* Black chevron */
  font-size: 1.5rem; /* Adjust size as needed */
}

.accordion-content {
  padding: 1rem 0;
  width: 100%; /* Ensure it spans the full width */
  box-sizing: border-box; /* Include padding and border in the element's total width */
  font-family: 'Inter';
  font-size: 24px;
  text-align: left;
  line-height: 1.5; /* Increase line height for dropdown content */
}



/* Responsive Styles */
@media (max-width: 768px) {
  .contact-page-container .two-column-section {
    flex-direction: column-reverse; /* Reverse column order */
  }

  .contact-page-container .left-column, .contact-page-container .right-column {
    padding: 2rem;
  }

  .contact-page-container .right-column h2 {
    font-size: 2rem;
    line-height: 3.5rem;
  }

  .accordion-title {
    font-size: 1.5rem;
  }

  .accordion-icon {
    font-size: 2rem;
  }

  .accordion-arrow {
    width: 20px;
    height: 20px;
    font-size: 1rem;
  }

  .accordion-content {
    font-size: 1.5rem;
  }

  .contact-form-title {
    font-size: 2rem;
    line-height: 3rem; /* Increase line height specifically for mobile */
  }
}
