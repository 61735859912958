/* src/components/Wishlist.css */
.wishlist-container {
    padding: 20px;
  }
  
  .wishlist-container h1 {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .wishlist-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .wishlist-card {
    width: calc(33.333% - 20px);
    border: 1px solid #ddd;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    position: relative;
    text-align: center;
    overflow: hidden;
  }
  
  .wishlist-details {
    margin-top: 10px;
  }
  
  .street-address {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: #555;
    margin: 0;
  }
  
  .community {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: #555;
    margin: 0;
  }
  
  .listing-price {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    color: #000;
    text-align: left;
    margin-top: 5px;
  }
  
  .separator-dot {
    margin: 0 5px;
  }
  
  .wishlist-icon.active {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    color: #9cdcf8; /* Filled blue heart */
  }
  
  @media (max-width: 768px) {
    .wishlist-card {
      width: calc(50% - 20px);
    }
  }
  
  @media (max-width: 480px) {
    .wishlist-card {
      width: 100%;
    }
  }
  