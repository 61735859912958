.google-reviews {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.0);
    text-align: center;
    position: relative; /* Ensure relative positioning for absolute elements */
}

h2 {
    margin-bottom: 1rem;
    font-family: 'Inter', sans-serif;
}

.reviews-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.review-card {
    padding: 40px;
    border-radius: 5px;
    margin: 0 15px;
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.review-text {
    font-size: 1.5rem!important;
    color: #333!important;
    margin-bottom: 0.5rem!important;
    font-family: 'Inter'!important;
	line-height:2rem!important;
}

.review-author {
    font-size: 1.2rem;
    color: #666;
}

.reviews-read-more-button {
    background-color: #f9f9f900;
    border: none;
    color: #9cdcf8;
    cursor: pointer;
    font-size: 1.3rem;
    padding: 1rem 1.7rem;
    border-radius: 3px;
    margin-top: 5px;
    font-family: 'Inter';
}

/* Custom styles for Swiper controls */
.swiper-pagination {
    display: none; /* Hide pagination dots */
}

.swiper-button-next,
.swiper-button-prev {
    color: black;
    position: absolute;
    top: 50%;
}

.slider-container {
    max-width: 1500px;
    margin: 0 auto; /* Center the slider container */
  }
  

/* Star rating styles */
.star-rating {
    display: flex;
    justify-content: center;
    margin-bottom: 30px; /* Adjust as needed */
}

.star {
    color: #ffc30c!important;
    font-size: 2.2rem; /* Adjust size as needed */
    margin: 0 2px;
    stroke: #ffc30c; /* Darker border */
    stroke-width: 10; /* Adjust border width as needed */
}

/* Center and side slide styles */
.center-slide .review-card {
    background-color: #f9f9f9; /* Darker grey color for center slide */
    transform: scale(1);
}

.side-slide .review-card {
    background-color: #fff; /* Lighter grey color for side slides */
    transform: scale(0.8); /* Scale down side slides */
}

/* Overall rating styles */
.overall-rating {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
}

.overall-rating .stars {
    display: flex;
    justify-content: center;
}

.overall-rating .star {
    font-size: 3rem; /* Larger size for overall rating stars */
    margin: 0 2px;
    stroke: #d3a518; /* Darker border */
    stroke-width: 12; /* Adjust border width as needed */
}

.review-count {
    font-size: 1.2rem;
    color: #666;
    margin-top: 0.5rem;
    font-family: 'Inter';
}

.google-logo {
    width: 100%; /* Adjust size as needed */
    height:auto;
    max-width:150px;
    margin-top: 1rem;
    margin-bottom:0rem;
}
