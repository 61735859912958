#contact-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center; /* Center the buttons horizontally */
  align-items: center;
  background-color: white; /* Set the bar's background to white */
  z-index: 9999;
  padding: 20px 0; /* Add some vertical padding */
  box-shadow:0px 0px 30px #00000015;
}

.contact-button {
  background-color: #9cdcf8; /* Blue color for buttons */
  color: #000!important;
  border: 1px solid #9cdcf8;
  border-radius: 5px; /* Slightly rounded corners */
  width: auto; /* Adjust width to fit content */
  margin: 0 10px; /* Space between buttons */
  padding: 18px 50px; /* Smaller button size */
  text-align: center;
  text-decoration: none;
  font-size: 1.5rem;
  transition: background-color 0.3s;
  font-family:Inter;
  font-weight:600;
}

.contact-button:hover {

  color:#000!important;
}

@media (max-width: 768px) {
  .contact-button {
    font-size: 1.25rem;
    padding: 18px 50px;
  }
}

@media (max-width: 420px) {
  .contact-button {
    font-size: 1.25rem;
    padding: 18px 50px;
  }
}

