.pricing-search-button {
    background: #9CDCF8;
    color: black;
    border: none;
    font-size: 1rem;
    padding: 15px 15px;
    cursor: pointer;
    font-family: 'Inter';
    font-weight: 500;
    margin-top: 20px !important;
    border-radius: 5px;
    display: table;
    margin-top: 15px;
    width:100%;
}

.pricing-cta-button {
    background: #9CDCF8;
    color: black;
    border: none;
    font-size: 1rem;
    padding: 15px 15px;
    cursor: pointer;
    font-family: 'Inter';
    font-weight: 500;
    margin-top: 30px !important;
    border-radius: 5px;
    display: table;
    margin-top: 15px;

}

.pricing-grid {
  display:flex;
}

.city-link {
  
  font-size:15px!important;
}

@media (max-width:1540px) {
  .pricing-grid {
    padding-left:15px;
    padding-right:15px;
  }
}

@media (max-width:1230px) {
  .pricing-grid {
    display:block;
  }

  .pricing-sidebar {
    max-width:1500px!important;
    width:100%!important;
  }

  .city-list {
    display:block;
    column-count:3!important;
  }

  .city-link {
    width:100%!important;
    font-size:15px!important;
  }
}


.pricing-subtitle {
  font-family: 'Inter';
        font-size: 1.7rem;
        font-weight: 700;
        color: #000;
        margin-bottom: 20px;
        margin-top:0px;
}

.pricing-text {
  font-size: 19px;
    font-family: 'Inter';
    color: #000;
    line-height: 2.5rem;
    margin-top: 0px;
    margin-bottom: 15px;
    align-content: start;
    text-align:left;
}

.pricing-list {
  margin-top:0px;
}

.pricing-list-item {
  font-size:22px;
}

.pricing-sidebar-title {
  font-family: 'Inter';
        font-size: 1.7rem;
        font-weight: 700;
        color: #000;
        margin-top: 20px;
        margin-bottom: 20px;
        align-content: start;
        text-align:start;
    
}

.pricing-title {
    font-family: 'Inter' !important;
    font-size: 2.5rem !important;
    font-weight: 700;
    padding-top: 30px;
    padding-bottom: 0px;
    margin-top: 0px;
    color: #000;
}
