/* Global Styles */
html,
body {
  height: 100%;
  margin: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

body {
  box-sizing: border-box;
  overflow-y: auto; /* Allow vertical scrolling on the body */
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

/* Commission Calculator Container */
.commission-calculator-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}



/* Slider Container */
.slider-container {
  position: relative;
  width: 100%;
  margin-bottom: 5rem;
  margin-top: 3rem;
}

/* Container for both the price and Home Price label */
.slider-label-container {
  position: absolute;
  top: -3rem; /* Adjust this based on your preference */
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2; /* Ensure the slider label is above other content */
}

.calculator-section {

  background-color: #f3f3f3;
  padding: 40px;
  margin-top:40px;
  max-width: 1500px;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;

}

@media (max-width: 1550px) {
  .calculator-section {

    background-color: #f3f3f3;
    padding: 40px;
    max-width: 1500px;
    border-radius: 5px;
    margin-left: 15px;
    margin-right: 15px;
  
  }
}

@media (max-width: 768px) {
  .calculator-section {

    background-color: #f3f3f3;
    padding: 20px;
    max-width: 1500px;
    border-radius: 5px;
    margin-left: 15px;
    margin-right: 15px;
  
  }
}


/* Adjust styles for the Home Price label */
.slider-label {
  font-size: 1rem;
  color: #333;
  margin-top: 0.5rem;
}

/* Adjust slider handle color */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  background: #577999;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  z-index: 3; /* Ensure the slider thumb is on top */
  margin-top: -10px; /* Move the handle above the range bar */
}

input[type="range"]::-moz-range-thumb {
  width: 24px;
  height: 24px;
  background: #577999;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  z-index: 3; /* Ensure the slider thumb is on top */
  margin-top: -10px; /* Move the handle above the range bar */
}

/* Style for the Home Price text */
.slider-label {
  text-align: center;
  font-size: 1rem;
  color: #333;
  margin-top: 0.5rem;
}

#modern-solution-realty-price,
#you-save-price {
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline;
}

.slider-value {
  position: absolute;
  top: -2rem;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  padding: 5px;
  background-color: #fff;
  border-radius: 5px;
}

@media (max-width:768px) {
  .slider-value {
    font-size:1rem;
  }
}

input[type="range"] {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  height: 8px;
  background: #cecece; /* Blue track */
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  overflow: visible; /* Ensure the thumb can overflow outside the track */
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  background: #000;
  border-radius: 50%;
  cursor: pointer;
  z-index: 3;
  margin-top: 0px; /* Moves the handle above the range bar */
  position: relative;
}

input[type="range"]::-moz-range-thumb {
  width: 24px;
  height: 24px;
  background: #577999;
  border-radius: 50%;
  cursor: pointer;
  z-index: 3;
  margin-top: -10px; /* Moves the handle above the range bar */
  position: relative;
}

/* Calculator Values */
.calculator-values {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
}

.value-column {

  padding:0.5rem;
  text-align: center;
}

.save-column {
  width: 100%;
  margin-top: 2rem;
  text-align: center;
}

.value-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
  font-family:'Inter';
}

.value {
  font-size: 2rem;
  font-weight: bold;
}

.partners-text {
  font-weight: bold;
  color: #9cdcf8;
}

/* Adjust styles for smaller screens */
@media (max-width: 768px) {
  .calculator-values {

    align-items: center;
    display:block;
  }

  .value-column {
    margin-bottom: 2rem;
  }

  .save-column {
    margin-top: 1rem;
  }
}

.two-column-section .left-column h3 {
  font-weight: 400;
}

.commission-calculator-container h5 {
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 1rem;
}

.commission-calculator-container {font-family:'Inter';}


#modern-solution-realty-price {
  color: #72bad9;
}


@media(max-width:768px) {
  .slider-container {

    margin-top:2.5rem;margin-bottom:0rem;
  }


}