.unique-testimonials-container {
  width: 100%;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.arrows-container {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 10px; /* Spacing between arrows and testimonials */
}

.slick-slider {
  width: 100%;
}

.slick-dots {
  bottom: -30px;
  text-align: center;
}

.unique-testimonial-slide {
  display: flex;
  justify-content: center;
}

.unique-testimonial {
  text-align: center;
  padding: 1.6rem;
  border-radius: 10px;
  background-color: #fff;
  margin: 0 auto;
  height: 340px; /* Set a fixed height for the testimonial box */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Ensure content doesn't overflow */
}

.unique-testimonial-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
}

/* Limit the testimonial text to 6 lines with an ellipsis */
.unique-testimonial p {
  font-size: 1.5rem;
  line-height: 2.7rem;
  font-family: 'Inter';
  font-weight: 200;
  display: -webkit-box;
  -webkit-line-clamp: 8; /* Limit to 6 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


.unique-testimonial h3 {
  font-size: 1.4rem!important;
  line-height:2.6rem!important;
  margin-bottom:0.8rem!important;
  margin-top: 0.8rem!important;
}

.custom-arrow {
  background-color: #9cdcf8;
  padding: 10px 20px;
  color: #9cdcf8;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  transition: background-color 0.3s ease;
}

.custom-arrow:hover {
  background-color: #82b9de;
}

@media (max-width: 768px) {
  .unique-testimonial {
    padding: 1.5rem;
    height: auto; /* Adjust for smaller screens */
  }

  .unique-testimonial-image {
    width: 80px;
    height: 80px;
  }

  .custom-arrow {
    padding: 8px 15px;
  }

  /* Ensure text is still limited on smaller screens */
  .unique-testimonial p {
    -webkit-line-clamp: 4; /* Limit to 4 lines on smaller screens */
  }
}

.unique-testimonial-slide img {
  width: 60px;
  max-width:60px;
}

.home-page-testimonials .slick-arrow, .home-page-testimonials .slick-prev {text-shadow:0px 0px 0px #fff;color:#9cdcf8!important;}

.home-page-testimonials .slick-arrow:before, .home-page-testimonials .slick-prev:before {color:#9cdcf8!important;}
.home-page-testimonials .slick-arrow:hover, .home-page-testimonials .slick-prev:hover {background-color:#fff!important;}