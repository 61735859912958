.contact-form {
  width: 100%;
}

.home-page .home-page-column:hover {background-color:#f3f3f3;transition-duration:0.1s;}

.contact-form h3 {
  font-family: 'Inter', sans-serif;
  margin-bottom: 1rem;
}

.contact-form form {
  display: flex;
  flex-direction: column;
}

.contact-form input[type='text'],
.contact-form input[type='email'],
.contact-form input[type='tel'],
.contact-form textarea {
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid #c9c9c9!important;
  border-radius: 5px!important;
  width: 100%; /* Applies only to text inputs and textarea */
  font-family: 'Inter';
  font-size:15px;
  background-color:#fff;
}

.name-fields,
.contact-fields {
  display: flex;
  gap: 10px; /* Adds space between the fields */
}

.name-fields input[type="text"],
.contact-fields input[type="email"],
.contact-fields input[type="tel"] {
  width: 50%; /* Set each input to 50% width */
}



.contact-form button,
.submit-button {
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  background-color: #9cdcf8;
  color: black;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight:500;
  padding: 15px;
}

.contact-form button:hover {
  transition-duration: 0.3s;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.checkbox-container input[type='checkbox'] {
  margin: 0 10px 0 0;
  width: auto; /* Ensure checkbox does not stretch */
  height: 16px;
  cursor: pointer;
}

.checkbox-container label {
  font-size: 14px;
  color: white;
  cursor: pointer;
}

