/* src/components/ImageSlider.css */
.slick-slide img {
  height: 60px;
  width:150px;
  border-radius: 5px;
}

.slick-prev, .slick-next {
  z-index: 2; /* Ensure arrows are above other elements */
  border: none;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-shadow: 0px 0px 20px #00000063;
}

.slick-prev:hover, .slick-next:hover {
  background-color: #74bddd;
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}

.slick-prev:before, .slick-next:before {
  font-size: 32px; /* Increase font size */
}

.slick-dots {
  position: absolute;
  bottom: 10px; /* Position dots inside the image */
  width: 100%;
  display: flex !important;
  justify-content: center;
  padding: 0;
  margin: 0;
  list-style: none;
  z-index: 2; /* Ensure dots are above other elements */
}

.slick-dots li {
  margin: 0px;
}

.slick-dots li button:before {
  font-size: 12px;
  color: transparent; /* Hide default dot */
}

.slick-dots li.slick-active button:before {
  color: transparent; /* Hide default dot */
}

.custom-dot {
  width: 14px;
  height: 14px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  border:1px solid black;
}

.slick-dots li.slick-active .custom-dot {
  background-color: #9cdcf8;
}
