.buy-page .video-container {
  position: relative;
  width: 100%!important;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width:100%;

  background-color: #F3F3F3;
  
 
}

.under-steps {justify-content: center;display:flex;}

.video-container video {
  
  border-radius:5px!important;
}

.buy-page .buy-video-container {
  margin-bottom: 15px;;
}

.buy-page .video-container video {
  margin:15px!important;
  margin-top:0px!important;

}

@media (max-width:1540px) {
  .buy-page .video-container video {
    padding-left:0px;
    padding-right:0px;
  }
}

@media (max-width:768px) {
  .buy-page .video-container video {
    padding-left:0px;
    padding-right:0px;
  }
}

.buy-page .content-image-section {
  max-width: 1500px;
  background-color: #F3F3F3;
  border-radius: 5px !important;
  padding:30px;
}

.buy-page .steps-container {
  justify-content:center;
  display:grid;
}

.buy-page .google-reviews-section {
  max-width: 100%;
}

.buy-page .counter-box img {
  width: 80px;
  height: 80px;
}

.buy-page .google-reviews-section .review-text {
  font-size: 1.5rem !important;
  line-height: 2.75rem !important;
}

.buy-page .review-card {
  box-shadow: 0px 0px 0px;
  border-width: 0px;
  padding: 3rem;
}

.buy-page .google-reviews-section {
  padding: 40px;
  background-color: #f3f3f3;
}



.buy-page .full-width-text-section {
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  padding-bottom:15px;
}

@media (max-width:1520px) {
  .buy-page .full-width-text-section {
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    padding-bottom:15px;
    padding-left:15px;
    padding-right:15px;
  }
  .buy-page .content-image-section {
    max-width: 1500px;
    background-color: #F3F3F3;
    border-radius: 5px !important;
    padding:30px;
    margin-left:15px;
    margin-right:15px;
  }

  .buy-page .toronto-star-container {
    background-color: #F3F3F3;
    max-width: 1500px;
    margin-left:15px;
    margin-right:15px;
    display: flex;
    gap: 1rem;
    padding: 30px;
    align-items: center;
    border-radius: 5px;
    
}
}

.buy-page .full-width-text-section p {
  font-size: 1.5rem;
  color: #000;
  line-height: 2.5rem;
  font-family: 'Inter';
  text-align:left;
}

.buy-page .featured-listings-heading h2 {
  text-align: center;
  font-family: 'Inter';
  color: #000;
  font-size: 2.5rem;
  margin-top: 30px;
}

.buy-page .custom-city-container h2 {
  text-align: center;
  font-family: 'Inter';
  color: #000;
  font-size: 2.5rem;
  margin-top: 30px;
}

.buy-page .toronto-star-heading {text-align:left;}
.buy-page .full-width-text-section h2 {
  text-align: left;
  font-family: 'Inter';
  color: #000;
  font-size: 2.5rem;
  margin-top:30px;
}

@media (max-width:768px) {
  .buy-page .full-width-text-section h2 {
    text-align: left;
    font-family: 'Inter';
    color: #000;
    font-size: 2rem;
    margin-top:15px;
  }
}



.counter-section h2 {
  text-align: left;
  font-family: 'Inter';
  color: #000;
  font-size: 2.5rem;;
  margin-top:0px;
  padding-bottom:15px;
}

.buy-page .google-reviews-section h2 {
  text-align: center;
  font-family: 'Inter';
  color: #000;
  font-size: 2.5rem;;
  margin-top:0px;
  padding-bottom:15px;
}

.buy-page .video-container video {
  max-width: 1500px;
}

.buy-page .google-reviews {
  background-color: #f3f3f3;
}

.buy-page .center-slide .review-card {
  background-color: white !important;
  border-width: 0px;
  padding: 3rem;
}

.buy-page .review-author {
  font-size: 1.25rem !important;
}

.buy-page #modern-solution-realty-price {
  background-color: #eef9fd;
}

.buy-page #you-save-price {
  background-color: #eef9fd;
}



.buy-page .unique-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  cursor: pointer;
  background-color: #9cdcf8;
  padding: 20px;
  border-radius: 50%;
}

.buy-page .commission-calculator-section {
  padding: 5rem 0rem;
}

.buy-page .unique-play-icon {
  width: 42.5px;
  height: 40px;
  color: white;
}

.buy-page .seo-cities h2 {
  margin-bottom: 15px;
  margin-top: 15px;
}

.buy-page .reviews-read-more-button {
  background: none;
  color: #4285F4;
  border: none;
  font-size: 1.5rem;
  padding: 10px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.5rem;
}

.buy-page .unique-play-icon:hover {
  opacity: 0.8;
}

.buy-page-content {
  padding: 2rem;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

.headline-section {
  text-align: center;
  margin-bottom: 12rem;
  margin-top: 8rem;
}

.main-heading {
  font-size: 2.5rem;
  color: #4285F4;
  text-align: center;
  font-family: 'Inter';
}

.main-paragraph {
  font-size: 1.5rem;
  color: #555;
  line-height: 2.5rem;
  text-align: center;
  font-family: 'Inter';
}

.content-image-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  font-family: 'Inter';
}

.text-content {
  flex: 1;
  padding: 0;
  text-align: left;
  font-family: 'Inter';
}

.text-content h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: left;
  font-family: 'Inter';
  color: #1b478c;
}

.text-content p {
  font-size: 1.5rem;
  color: #000;
  margin-bottom: 1rem;
  line-height: 2.5rem;
  font-family: 'Inter';
}

.image-content {
  flex: 1;
  display: flex;
  justify-content: center;
}

.image-placeholder {
  width: 100%;
  max-width: none;
  height: auto;
}



/* Counter Section */
.counter-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  padding-top:40px;
  padding-bottom:40px;
  background-color: #ecf5f9;
  max-width:1500px;
  margin:auto;
}

@media (max-width:768px) {
  .counter-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    padding-top:40px;
    padding-bottom:40px;
    background-color: #ecf5f9;
    max-width:1500px;
    margin-left:15px;
    margin-right:15px;
  }
}

.counter-row {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 0rem;
}

.counter-box {
  background-color:#F3F3F3;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  width: 100%;
  margin: 7.5px;
  padding:15px;
}

.counter-box .counter-icon {
  width: 40px;
  height: 40px;
  margin-bottom: 10px; /* Add space between the icon and the value */
}

.counter-box h3 {
  font-size: 2.75rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  margin-top:15px;
  color: #2c3e50;
  text-transform: uppercase;
}

.counter-box p {
  line-height: 2rem;
  color: #2c3e50;
  font-family: 'Inter';
  text-transform: uppercase;
  font-weight: 600;
}

@media (max-width: 768px) {
  .counter-row {
    flex-direction: column;
    align-items: center;
    margin-left:15px;
    margin-right:15px;
  }

  .counter-box {
    width: 100%;
    padding-left:15px;
    padding-right:15px;
    margin-bottom:15px;
  }
}

.buy-page .video-container video {border-radius:5px;}

.buy-page .image-content img {border-radius:5px;}

/* Buttons Section */
.buy-page .buttons-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #9cdcf8;
  padding: 15px;
  border-radius: 5px;
  margin: 20px 10px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

.white-button {
  background-color: white;
  color: black;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: none;
  font-weight: 500;
  font-size: 22px;
}

.white-button:hover {
  background-color: #f0f0f0;
}

/* Media Queries */
@media (max-width: 768px) {

  .video-container {padding:0rem 1rem!important;}



  .content-image-section {
    flex-direction: column;
  }

  .text-content,
  .image-content {
    padding: 0;
    margin-bottom: 1.5rem;
  }

  .headline-section h1 {
    font-size: 2rem;
  }

  .main-paragraph,
  .text-content p {
    text-align: center;
  }

  .call-to-action h2 {
    font-size: 1.5rem;
  }

  .cta-button {
    font-size: 1rem;
  }

  .video-container {
    padding: 0px;
  }

  .buy-page .video-container video {
    width: 100%;
    height: auto;

    border-radius: 5px;
  }

  /* Ensure consistent order: heading > text > image */
  .content-image-section {
    flex-direction: column;
  }

  .text-content {
    order: 1;
    text-align: center;
  }

  .image-content {
    order: 2;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .buy-page .full-width-text-section {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .buy-page .content-image-section {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .buy-page .content-image-section h2 {
    text-align: center;
  }

  .buy-page .content-image-section p {
    text-align: center !important;
    font-size:1rem;
  }
}

/* Add gap between text and image for desktop */
@media (min-width: 769px) {
  .content-image-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    gap: 3rem; /* Add this line for a gap between text and image */
    font-family: 'Inter';
  }

  /* Right-align text for sections where the text is on the right */
  .content-image-section.text-right .text-content {
    text-align: right;
    font-family: 'Inter';
  
  }
}

/* Align image-content based on text placement */
.content-image-section.text-right .image-content {
  display: flex;
  justify-content: flex-start; /* Align image to the right */
}

/* Fade-in Animation */
.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.fade-in.visible {
  opacity: 1;
  transform: translateY(0);
}

.content-image-section .image-content {
  display: flex;
  justify-content: flex-end; /* Align image to the left */
}

/* Right-align the heading for the specific section, but keep the paragraph left-aligned */
.content-image-section.text-right .text-content h2 {
  text-align: right;
  font-family: 'Inter';
}


.content-image-section.text-right .text-content p {
  text-align: right;
  font-family: 'Inter';
}

.buy-page .counter-section {
  max-width: 1500px;
  background-color: white;

}

@media (max-width: 768px) {
  .buy-page .full-width-text-section {
    padding-left: 15px;
    padding-right: 15px;
    padding-top:15px;
  }

  .buy-page .content-image-section {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .buy-page .content-image-section h2 {
    text-align: center;
  }

  .content-image-section p {
    text-align: center;
  }

  .buy-page .content-image-section.text-right .text-content h2 {text-align:center;}

.buy-page .google-logo {
  width: 200px !important;
}

.buy-page .slider-container {
  margin-top: 0rem;
  margin-bottom: 0rem;
}
}



.buy-page .home-page-lets-talk {
  width: unset;
  background-color: white;
  text-align: center;
  padding: 4rem 2rem;
  margin: 2rem 10px;
  border-radius: 15px;
  margin-left:auto;margin-right:auto;

}

.buy-page .home-page-lets-talk h2 {color:white;}

.buy-page .home-page-toronto-star {margin:10px;}

.buy-page .home-page-map-section {margin:5rem 2rem;}

.buy-page .home-page-toronto-star {box-shadow:unset;margin-left:auto;margin-right:auto;}

.buy-page .contact-form {margin:1rem;width:90%; max-width:1500px; margin-left:auto!important;margin-right:auto!important;}

.buy-page .contact-form input,.buy-page .contact-form textarea {border-width:0px; background-color:#f3f3f3; padding:15px;}

.buy-page .checkbox-container label {color:black;font-size:20px;font-family:'Inter';}

.buy-page .video-container {
  position: relative;
  padding-left:15px;
  padding-right:15px;
  max-width: 100%;
  padding-bottom: 11px !important;}

  @media (max-width:768px) {
    .buy-page .video-container {
      position: relative;
      aspect-ratio: auto;
      max-width: 100%;
      padding-bottom: 0px !important;}

      .buy-page h2 {
        text-align: center;
        font-family: 'Inter';
        color: #000;
        font-size: 2rem;
    }

    .buy-page .full-width-text-section p {
      font-size: 1rem;
      color: #000;
      line-height: 2.5rem;
      font-family: 'Inter';
  }
  }